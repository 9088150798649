input[type=text].search-field-input {
    font-family: $primary-font;
    padding: 20px 12px;
    font-weight: bold;
    font-size: 16px;
    background-color: $color-beige1;
    border-color: $color-beige1;
    &.is-active {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23000' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
		background-repeat: no-repeat;
		background-position: center right calc(.375em + .1875rem);
		background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
}
.search-widget-container {
	.search-field {
        font-family: $primary-font;
        ::placeholder {
            color: #999;
            opacity: 1;
            text-transform: uppercase;
            letter-spacing: 0.0085rem;
        }

		.search-off {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 10%;
			cursor: pointer;
		}
	}
}
