.all-customer-block-titles {
    @extend .mb-3;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 0.085rem;
    color: $color-black;
    border-bottom: 4px solid $color-black;
}
.all-customer-action-links {
    font-weight: bold;
    letter-spacing: 0.085rem;
    color: $color-red;
    background-image: url(../images/pfeil1.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 0.85rem;
    padding-right: 20px;
    margin-right: 15px;
    border: none;
}



.login-container {
    @extend .mb-5;
    @include make-row;
    @include make-col-ready;
    @include make-col(12);

    .block {
        @include make-col-ready;
        @include make-col(12);

        @include media-breakpoint-up(sm) {
            @include make-col(6);
        }

        .block-title {
            @extend .all-customer-block-titles;
        }
    }
}


.account,
.customer-account-login,
.customer-account-create,
.pellegrini-customer-downloads {
    .page-title {
        @extend .mt-4;
        @extend .mb-4;
        font-family: $secondary-font;
        text-align: left;
        color: $color-black;
        letter-spacing: 0.095rem;
    }

    .column.main {
        @include make-row;

        .block {
            @include make-col-ready;
            @include make-col(12);

            @include media-breakpoint-up(lg) {
                @include make-col(6);
            }

            .block-title {
                @extend .all-customer-block-titles;
            }

            .box-actions {
                @extend .mb-3;
            }
        }

        a.action {
            @extend .all-customer-action-links;
        }

        .form,
        .form-address-edit {
            @extend .mb-5;
            @include make-row;
            @include make-col-ready;
            @include make-col(12);


            .fieldset {
                @include make-col-ready;
                @include make-col(12);


                &.create.info,
                &.address {
                    @include make-col-ready;
                    @include make-col(12);

                    @include media-breakpoint-up(xxl) {
                        @include make-col(6);
                    }
                }

                &.password,
                &.info {
                    @include make-col-ready;
                    @include make-col(12);

                    @include media-breakpoint-up(lg) {
                        @include make-col(6);
                    }
                }


                &.create.account {
                    @include make-col-ready;
                    @include make-col(12);
                }

                & > .legend {
                    @extend .all-customer-block-titles;
                }
            }
        }
    }

    .download-link-list a {
        @extend .download-links;
    }
}
