.c-social-links {
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    .c-social-link {
        @extend .mr-2;
        @extend .pt-4;

        display: inline-block;

        a {
            display: inline-block;
            transition: opacity 300ms ease-in;

            &:hover {
                opacity: 0.8;
            }
        }

        svg {
            width: 50px;
            height: 50px;
        }
    }
}
