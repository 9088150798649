.filter-widget-container {

    .filter-widget-button-col {
        margin: 10px 0;

        button {
            margin: 0 10px 10px 0;
            border-radius: 0;
            background: transparent;
            border: 1px solid $color-grey;
            color: $color-grey;
            font-size: 16px;
            font-weight: regular;
            padding: 0.275rem 0.75rem;
        }

        button:focus {
            outline: none !important;
            box-shadow: none !important;
        }


        button:not(:disabled):not(.disabled):focus,
        button:not(:disabled):not(.disabled):active {
            border-radius: 0;
            background: transparent;
            border: 1px solid $color-grey;
            color: $color-grey;
        }

        .wine-counter {
            float: right;
            clear: both;
        }
    }
}

// Override Bootstrap
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) inset, 0 0 0 rgba(126, 239, 104, 0) !important;
    outline: 0 none;
}

// Override Bootstrap-Select
.bootstrap-select {
    font-family: $primary-font;
    background-color: $color-beige1 !important;
    margin-bottom: 10px !important;
    border-radius: 0;

    a:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .btn:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .popover-header {
        background-color: $color-beige1;
        border-bottom: none;
        padding: 1rem 0.75rem 0.5rem;
        font-size: 16px;

        .close {
            float: left !important;
            font-weight: normal;
            font-size: 2rem;
            margin: -4px 10px 0 0;
            opacity: 1;
            text-shadow: none;

            @media (min-width: 992px) {
                margin: -6px 10px 0 0;
            }

            @media (min-width: 1200px) {
                margin: -9px 10px 0 0;
            }
        }
    }

    .dropdown-toggle {
        border-radius: 0;
        background-color: $color-beige1 !important;
        border: 1px solid $color-beige1 !important;
    }

    .dropdown-menu {
        border-radius: 0;
        background-color: $color-beige1 !important;
        border: 1px solid $color-beige1 !important;
        padding: 0 0 5px;
        background-clip: border-box;

        li {
            margin-bottom: 0;
        
            .dropdown-item {
                padding: 0.275rem 0.8rem;
                font-size: 16px;
        
                &:hover {
                    font-weight: normal;
                    background-color: $color-beige1;
                }
        
                &:active {
                    color: black;
                    font-weight: 900;
                    background-color: $color-beige1;
                }
        
                &.selected,
                &.active {
                    color: black;
                    font-weight: 900;
                    background-color: $color-beige1;
                }
            }
        }

        .no-results {
            font-size: 16px;
            background-color: $color-beige1;
            margin: 0 12px;
        }

        &.show {
            top: -3px !important;
        }
    }

    .filter-option {
        border-radius: 0 !important;
        font-size: 16px;
        line-height: 20px;
        font-weight: 900;

        .filter-option-inner {
            border-radius: 0 !important;
            margin-top: 5px;

            .filter-option-inner-inner {
                text-transform: uppercase;
                margin-top: -3px;

                @media (min-width: 992px) {
                    margin-top: -2px;
                }

                @media (min-width: 1200px) {
                    margin-top: 5px;
                }
            }
        }
    }

    .bs-searchbox {
        padding: 0 15px 15px;

        .form-control {
            font-size: 16px;
            border-radius: 0;
            border-top: 0 solid black;
            border-left: 0 solid black;
            border-right: 0 solid black;
            border-bottom: 1px solid black;
            background-color: $color-beige1;
            padding: 0;
            height: 30px;
        }
    }
}
