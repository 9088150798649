// Header
.l-header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	background-color: $color-white;
	color: $color-black;
	box-shadow: $header-shadow-upper;
	height: 88px;

	.c-hamburger {
		position: relative;

		#menu-icon {
			height: 40px;
			width: 40px;
			margin: 24px 0;
			cursor: pointer;
		}

		.menu-label {
			font-family: $primary-font;
			font-size: 1rem;
			text-transform: uppercase;
			position: absolute;
			left: 48px;
			top: 50%;
			transform: translateY(-50%);
			color: $color-beige3;
			cursor: pointer;
		}

		@include media-breakpoint-down(sm) {
			.menu-label {
				display: none;
			}
		}
	}

	#logo {
		width: 42.66px;
		height: 34px;
		margin: 27px 0;
	}

	#account-link {
		width: 28.12px;
		height: 28.28px;
		margin: 28px 0;
		position: relative;

		.c-social-link {
			padding-top: 0 !important;
			a {
				position: absolute;
				top: 0;
				right: 30px;

				svg {
					height: 30px;
					width: auto;
				}
			}

			&.c-social-links-instagram a {
				right: 64px;
			}
		}

		@include media-breakpoint-up(sm) {
			.c-social-link {
				a {
					right: 33px;
				}

				&.c-social-links-instagram a {
					right: 70px;
				}
			}
		}


		@include media-breakpoint-up(md) {
			.c-social-link {
				a {
					right: 114px;
				}

				&.c-social-links-instagram a {
					right: 153px;
				}
			}
		}


		@include media-breakpoint-up(lg) {
			.c-social-link {
				a {
					right: 122px;
				}

				&.c-social-links-instagram a {
					right: 162px;
				}
			}
		}


		@include media-breakpoint-up(xl) {
			.c-social-link {
				a {
					right: 140px;
				}

				&.c-social-links-instagram a {
					right: 180px;
				}
			}
		}
	}

	.account-links {
		position: absolute;
		right: 0;
		top: 0;
		width: 300px;
		text-align: right;

		.customer-link {
			vertical-align: middle;
			line-height: 1rem;

			// display: none;
			//
			// @include media-breakpoint-up(md) {
			display: inline-block;

			// }

			a {
				font-size: 1.0rem;
				text-transform: uppercase;
				color: $color-beige3;

				svg {
					height: 30px;
				}
			}

			.minicart-wrapper {
				margin-top: 0;
				margin-left: 15px;

				.action.showcart {
					&::before {
						content: ' ';
						color: $color-beige3;
						height: 30px;
						width: 30px;
						background-image: url(../images/Cart-beige.svg);
						background-size: contain;
						background-repeat: no-repeat;
					}

					.counter.qty {
						background-color: $color-beige3;
						height: 16px;
						line-height: 14px;

						.counter-number {
							font-size: 0.8rem;
						}

						&:after {
							content: 'Fl.';
							font-size: 0.8rem;
						}
					}
				}
			}
		}
	}

	a {
		color: $color-black;
		padding: 0;

		&:hover {
			color: rgba($color-black, 0.7);
		}
	}
}

header.page-header {
	@extend .l-header;
}
