// COLORS
$color-white: #fff;
$color-black: #171717;
$color-red: #6f152f;

// redifine magento colors
$color-blue1             : $color-red !default;
$color-blue2             : $color-red  !default;
$color-blue3             : $color-red !default;
$color-sky-blue1         : $color-red !default;

$color-beige1: #ebe7db;
$color-beige2: #d3c6a8;
$color-beige3: #b6a57c;
$color-beige-grey: #dedacf;
$color-grey: #bcbcbc;


// Tools
$header-shadow-upper: 0 1px 1px rgba(200, 200, 200, 0.6) !important;
$header-shadow-lower: 0 2px 2px rgba(200, 200, 200, 0.6) !important;
