.l-headervisual {
    .container {
        max-width: 576px * 1.2;

        @include media-breakpoint-up (md) {
            max-width: 768px * 1.06;
        }

        @include media-breakpoint-up (lg) {
            max-width: 992px * 1.06;
        }

        @include media-breakpoint-up (xl) {
            max-width: 1200px * 1.06;
        }

        @include media-breakpoint-up (xxl) {
            max-width: 1600px * 1.06;
        }
    }
}
