#close-menu-icon {
    .a,
    .b {
        fill: none;
    }

    .b {
        stroke: #b6a57c;
    }

    .c,
    .d {
        stroke: none;
    }

    .d {
        fill: #b6a57c;
    }
}

.close-icon {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    overflow: hidden;
    vertical-align: middle;
    margin-left: 10px;
    margin-top: -3px;

    &:hover {
        &::before,
        &::after {
            background: $color-grey;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: $color-grey;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }

    &.hairline {
        &::before,
        &::after {
            height: 1px;
        }
    }

    &.in-dropdown {
        margin-top: 4px !important;
        font-weight: normal !important;

        &:hover {
            &::before,
            &::after {
                background: black !important;
                height: 1px !important;
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: 1px !important;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background: black !important;
        }
    }
}

.delete-icon {
    @extend .close-icon;

    &::after,
    &::before {
        width: 22px;
        height: 22px;
        background: black;
    }
}

.plus-icon {
    @extend .close-icon;
    margin-right: 10px;

    &::after,
    &::before {
        width: 22px;
        height: 22px;
        transform: rotate(0deg);
        background: black;
    }

    &::after {
        transform: rotate(90deg);
        left: -2px;
    }
}

.minus-icon {
    @extend .close-icon;
    margin-right: 10px;

    &::before {
        transform: rotate(0deg);
        background: black;
    }

    &::after {
        transform: rotate(0deg);
        background: black;
    }
}

.check-mark {
    display: inline-block;
    transform: rotate(45deg);
    height: 16px;
    width: 8px;
    border-bottom: 2px solid $color-red;
    border-right: 2px solid $color-red;

    &::after {
        display: none !important;
    }
}
