// ctype SCSS
.ce-headerslider-slider {
    .slick-dots {
        bottom: 5%;
    }

    .ce-headerslider-slide {
        z-index: 5;
        position: relative;

        picture img {
            width: 100%;
        }

        .ce-headerslider-darken {
            background-color: $color-black;
            opacity: 0.4;
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .ce-headerslider-textelements {
            z-index: 20;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            color: $color-white;
            text-align: center;
            // temporär
            padding-top: 20%;
            text-transform: uppercase;
            font-family: $primary-font;

            .ce-headerslider-date {
                font-size: $h4-font-size;
                font-weight: bold;
                font-family: $secondary-font;
            }

            .ce-headerslider-header {
                font-size: $h1-font-size;
                letter-spacing: 0.22rem;
                max-width: 80%;
                margin: 0 auto;
            }

            .ce-headerslider-subheader {
                font-size: $h3-font-size;
                font-family: $secondary-font;
            }

            .ce-headerslider-link {
                @include more-link-with-border;
            }
        }
    }
}
