.slick-dots {
    li {
        width: 34px;

        button:before {
            content: "";
            width: 28px;
            height: 6px;
            background-color: $color-red;
            opacity: 1;
        }
    }

    li.slick-active {
        button:before {
            background-color: $color-beige-grey;
        }
    }
}
