.catalog-category-view {
    .products-list {
        border-top: 1px solid $color-black;
    }

    .product-item-offer {
        .product-image-container {
            img {
                border: 1px solid $color-black;
                padding: 4px;
            }
        }

        .product-item-details {
            border: none;

            .offer-title {
                h2 {
                    text-align: left;
                    margin-top: 0;
                }
            }

            .offer-text {
                font-weight: normal;
                line-height: 1.8;
                text-transform: none;
                letter-spacing: 0;
            }
        }

        .price-box {
            &.price-offer {
                color: $color-red;
                font-size: 1.8rem;
                font-weight: bold;
            }

            &.price-normal,
            .price-tier-price-price {
                font-weight: normal;
                text-decoration: line-through;
            }
        }
    }

    .product-item-offer {
        .box-action a.action {
            display: block;
            font-weight: 200;
            text-transform: uppercase;
            letter-spacing: 0.085rem;
            color: $color-black;
            background-image: url(../images/pfeil1.svg);
            background-repeat: no-repeat;
            background-position: right 34px;
            background-size: 0.85rem;
            padding: 25px 0 15px 0;
            border: none;
        }

        .product-view-text.product-view-download-links.offer-link {
            @extend .pb-1;
            display: block;
            background-image: url(../images/downloads.svg);
            background-position: right center;
            background-size: 1.0rem;
            background-repeat: no-repeat;
            padding-right: 25px;

            a {
                color: $color-black;
                text-transform: uppercase;
                letter-spacing: 0.085rem;
            }
        }
    }
}
