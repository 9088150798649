.button,
button,
button.action.primary,
a.action.primary {
    background-color: $color-black;
    color: $color-white;
    font-family: $primary-font;
    text-transform: uppercase;
    letter-spacing: 0.085rem;
    font-size: 1.0rem;
    font-weight: 200;
    border: none;

    &:hover,
    &:focus,
    &:active  {
        background-color: $color-red;
        color: $color-white;
        border: none;
    }
}
.customer-account-login .column.main .actions-toolbar .primary a.action.primary,
.column.main .actions-toolbar .primary a.action.primary {
    font-family: $primary-font;
    text-transform: uppercase;
    letter-spacing: 0.085rem;
    color: $color-white !important;
    background-image: none !important;
    font-size: 1.0rem;
    border: none;
    border-radius: 0;

    &:hover,
    &:focus,
    &:active {
        background-color: $color-red;
        color: $color-white !important;
        border: none;
    }
}

.actions-toolbar {
    .primary,
    .secondary {
        margin-right: 15px;
    }
}
