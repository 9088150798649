@font-face {
    font-family: 'Publico Headline';
    src: url("PublicoHeadline-Roman-Web.eot");
    src: url("../fonts/PublicoHeadline/PublicoHeadline-Roman-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/PublicoHeadline/PublicoHeadline-Roman-Web.woff2") format("woff2"), url("../fonts/PublicoHeadline/PublicoHeadline-Roman-Web.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/SourceSansPro/SourceSansPro-Bold.woff2") format("woff2"), url("../fonts/SourceSansPro/SourceSansPro-Bold.woff") format("woff"), url("../fonts/SourceSansPro/SourceSansPro-Bold.otf") format("opentype");
    font-style: bold;
    font-weight: 700;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/SourceSansPro/SourceSansPro-SemiBold.woff2") format("woff2"), url("../fonts/SourceSansPro/SourceSansPro-SemiBold.woff") format("woff"), url("../fonts/SourceSansPro/SourceSansPro-SemiBold.otf") format("opentype");
    font-style: bold;
    font-weight: 600;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/SourceSansPro/SourceSansPro-Regular.woff2") format("woff2"), url("../fonts/SourceSansPro/SourceSansPro-Regular.woff") format("woff"), url("../fonts/SourceSansPro/SourceSansPro-Regular.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Source Code Pro";
    src: url("../fonts/SourceCodePro/SourceCodePro-Regular.woff2") format("woff2"), url("../fonts/SourceCodePro/SourceCodePro-Regular.woff") format("woff"), url("../fonts/SourceCodePro/SourceCodePro-Regular.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

// TYPOGRAPHY
html {
    -webkit-font-smoothing: antialiased;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 1.25 / 1), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
    html {
        -webkit-font-smoothing: subpixel-antialiased;
    }
}

//Font-Family
$secondary-font: "Publico Headline", serif;
$primary-font: "Source Sans Pro", "Arial", "Helvetica", sans-serif;


$h1-font-size: $font-size-base * 2.00;
$h2-font-size: $font-size-base * 1.15;
$h3-font-size: $font-size-base * 0.8;
$h4-font-size: $font-size-base * 0.7;
$h5-font-size: $font-size-base * 0.6;

body {
    font-family: $primary-font;
}

//
//Headings
h1,
h2 {
    //    font-family: $primary-font;
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    color: $color-red;
    margin-top: 2rem;
    font-size: $h1-font-size;
    letter-spacing: 0.22rem;
    line-height: 1.3;
}

main > h1:first-child {
    margin-top: 144px;
    margin-bottom: 0;
}

main > h1 ~ h2 {
    margin-top: 0;
    margin-bottom: 130px;
}

h2 {
    font-size: $h2-font-size;
    letter-spacing: 0.15rem;
    margin-top: 1.2rem;
    color: $color-black;
}

h3,
h4 {
    font-family: $secondary-font;
    font-weight: bold;
    color: $color-red;
    line-height: 1.3;
}

h4 {
    line-height: 32px;

    //
}

//Paragraph
p {
    //    font-family: $primary-font;
    line-height: 1.8rem;
    margin-bottom: 2rem;
}
a {
    color: $color-red;
    &:hover {
        color: lighten($color-red, 5%);
        text-decoration: none;
    }
}

b,
strong {
    font-weight: bold;
}
