// OVERWRITE BOOTSTRAP VARIABLES
//
// Colors
//
$white: $color-white;
$black: $color-black;


// Factor to calculate between rem and pixel
$remPixelFactor: 16;

// number of post decimal positions for rem
// e.g. write 1000 for 3 numbers
$remPostDecimalPositionNumber: 1000;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1560px
) !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: ($spacer * 0.5),
        2: $spacer,
        3: ($spacer * 2.5),
        4: ($spacer * 5),
        5: ($spacer * 9)
    ),
    $spacers
);

$border-radius: 0;

