.catalog-category-view {
    .wine-counter {
        display: none;
    }

    .page-title-wrapper {
        display: none;
    }

    .product-items {
        @include make-row;

        margin-top: 20px;
        list-style: none;

        .product-item {
            z-index: 40;
            background-color: $color-white;

            .product-item-details-overlay {
                position: absolute;
                z-index: 30;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                display: none;

                .product-item-more-link {
                    @include more-link-with-border;

                    z-index: 30;
                    color: $color-black;
                    margin-top: 40%;

                    &:after,
                    &:before {
                        background-color: $color-black;
                    }
                }

                .product-info-prices {
                    @extend .mt-2;

                    @extend .pt-2;

                    border-top: none;
                    border-bottom: none;

                    .price-box-in-list {
                        text-align: center;
                        font-weight: bold;
                        font-size: 1.2rem;

                        .price-label {
                            display: none;
                        }

                        &.price-final_price-in-list:before {
                            content: '';
                            font-size: 0.8rem;
                            font-weight: normal;
                            text-transform: none;
                        }

                        &.price-tier-price-in-list:before {
                            content: '';
                            font-size: 0.8rem;
                            font-weight: normal;
                            text-transform: none;
                        }
                    }

                    .price-container {
                        padding: 0;
                    }
                }

                .to-cart-tool {
                    margin: 15px 0 5px 0;

                    > div {
                        background-color: $color-beige1;
                    }

                    .field.qty {
                        max-width: 60px;
                        position: relative;

                        input[type="number"] {
                            background-color: $color-beige1;
                            border: none;
                            text-align: center;
                            color: #000;
                            opacity: 1;
                            font-weight: 700;
                        }

                        // &::after {
                        //     position: absolute;
                        //     left: 0;
                        //     right: 0;
                        //     top: 0;
                        //     bottom: 0;
                        //     content: '';
                        // }
                    }
                }
            }

            &:hover {
                z-index: 10;

                .product-item-details-overlay {
                    display: block;
                }
            }

            &:hover:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 20;
                background-color: $color-white;
                opacity: 0.8;
            }


            .new-item,
            .new-equipment {
                width: 100px;
                height: 100px;
                position: absolute;
                right: 15px;
                top: 15px;
                background-image: url(../images/neu-im-sortiment.svg);
                background-size: 100px 100px;
                background-repeat: no-repeat;
                background-position: left top;
            }


            .new-equipment {
                background-image: url(../images/neue-ausstattung.svg);
            }

            .icons-in-list-left {
                width: 75px;
                position: absolute;
                left: 15px;
                top: 15px;

                .listout,
                .vintageout,
                .wineryonly {
                    width: 75px;
                    height: 75px;
                    margin-bottom: 5px;
                    background-image: url(../images/ausgelistet.svg);
                    background-size: 75px 75px;
                    background-repeat: no-repeat;
                    background-position: left top;
                }
                .listout {
                    background-image: url(../images/ausgelistet.svg);
                }
                .vintageout {
                    background-image: url(../images/jahrgang_ausverkauft.svg);
                }
                .wineryonly {
                    background-image: url(../images/nur_ab_weingut.svg);
                }
            }



            .is-bio {
                width: 40px;
                height: 40px;
            }

            .product-image-container {
                position: relative;

                .product-info-bio {
                    position: absolute;
                    left: 0;
                    bottom: 5px;
                }

                .product-info-vegan {
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                }

                .product-image-wrapper {
                    padding-bottom: 0;

                    .product-image-photo {
                        max-width: 100%;
                        padding-bottom: 0.7rem;
                    }
                }
            }

            @include make-col-ready;
            @include make-col(12);

            @include media-breakpoint-up(sm) {
                @include make-col(6);
            }


            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }


            @include media-breakpoint-up(xxl) {
                @include make-col(3);
            }


            &-details {
                border-top: 1px solid $color-black;
            }

            &-info {
                border-bottom: 4px solid $color-black;
                height: 100%;
                margin: 0 0 0.5rem 0;
                padding: 4rem 0 2rem 0;

                .product-item-appellation,
                .product-item-sku-winery {
                    text-transform: uppercase;
                    font-size: 0.8em;
                    padding: 0.5rem 0 0.5rem 0;
                    letter-spacing: 0.075em;
                    line-height: 1.2rem;
                }

                .product-item-name {
                    .product-item-link {
                        text-transform: uppercase;
                        display: block;
                        line-height: 1.1em;
                        font-weight: bold;
                        font-size: 1em;
                        color: $color-black;
                        letter-spacing: 0.075em;
                    }
                }
            }

            .price-details {
                display: none;
            }

            .price-box {
                position: absolute;
                bottom: 12px;
                right: 15px;
                font-size: 1.2em;
                font-weight: 700;
                letter-spacing: 0.02em;

                .minimal-price-in-list {
                    .price-label {
                        font-size: 0.8rem;
                        font-weight: normal;
                    }
                }

                .old-price,
                .special-price {
                    .price-label {
                        display: none;
                    }
                }

                .old-price {
                    .price {
                        font-weight: 200;
                        text-decoration: line-through;
                    }
                }

                .special-price {
                    .price {
                        color: $color-red;
                    }
                }
            }

            .product-item-capacity {
                //display: none;
                position: absolute;
                bottom: 16px;
                left: 15px;
                font-size: 0.8em;
                font-weight: normal;

                &:after {
                    content: 'l';
                    padding-left: 0.2em;
                }
            }
        }
    }
}

.catalog-product-view {
    .product-view-winery-link {
        @include more-link-with-border;

        max-width: 100%;
        min-width: 100%;
        color: $color-black;

        &:after,
        &:before {
            background-color: $color-black;
        }
    }

    .product-view-logo {
        text-align: center;

        img {
            width: 80%;
            height: auto;
            max-width: 200px;
            max-height: 200px;
        }
    }

    .column.main {
        @include make-row;

        .product-info-main {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.1;
            letter-spacing: 0.08rem;

            @include make-col-ready;
            @include make-col(12);

            order: 2;

            @include media-breakpoint-up(sm) {
                @include make-col(7);
            }


            .description-content {
                @extend .pt-2;

                @extend .pb-2;

                font-weight: normal;
                line-height: 1.8;
                text-transform: none;
                letter-spacing: 0;
            }

            .evp {
                text-align: right;
                font-weight: normal;
            }

            .product-icons-top {
                min-height: 200px;
                position: relative;

                .listout,
                .vintageout,
                .wineryonly,
                .new-item,
                .new-equipment {
                    width: 150px;
                    height: 150px;
                    position: relative;
                    display: inline-block;
                    margin: 0 20px 20px 0;
                    background-image: url(../images/neu-im-sortiment.svg);
                    background-size: 150px 150px;
                    background-repeat: no-repeat;
                    background-position: left top;
                }

                .new-equipment {
                    background-image: url(../images/neue-ausstattung.svg);
                }
                .listout {
                    background-image: url(../images/ausgelistet.svg);
                }
                .vintageout {
                    background-image: url(../images/jahrgang_ausverkauft.svg);
                }
                .wineryonly {
                    background-image: url(../images/nur_ab_weingut.svg);
                }

            }

            .page-title-wrapper.product {
                h1 {
                    font-size: 1rem;
                    margin: 0;
                }
            }

            .product-info-prices-wrapper {
                display: none;

                .product-info-prices {
                    @extend .mt-2;

                    @extend .pt-2;

                    border-top: 1px solid $color-grey;
                    border-bottom: 4px solid $color-black;

                    .price-box {
                        @include make-col-ready;
                        @include make-col(12);

                        text-align: right;
                        padding-right: 0;

                        .label {
                            display: none;
                        }

                        &.price-final_price:before {
                            content: '';
                            font-size: 0.8rem;
                            font-weight: normal;
                            text-transform: none;
                        }

                        &.price-tier-price:before {
                            content: '';
                            font-size: 0.8rem;
                            font-weight: normal;
                            text-transform: none;
                        }
                    }

                    .price-container {
                        padding: 0;
                    }
                }
            }

            .price-details {
                color: $color-grey;
                font-size: 0.8rem;
                font-weight: normal;
                text-align: right;
            }

            .product-item-info {
                font-size: 1rem;
                font-weight: normal;

                &.appellation {
                    font-weight: bold;
                }

                &.details {
                    @extend .mt-2;

                    @extend .pt-1;

                    border-top: 2px solid $color-grey;

                    .sku {
                        @extend .pb-2;

                        font-weight: bold;
                    }

                    .product-info-icon {
                        padding: 1rem 0 0.5rem 2.2rem;
                        background-image: url(../images/Grapes.svg);
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-size: 1.0rem;

                        &.alcohol {
                            background-image: url(../images/Glass.svg);
                            background-size: 0.85rem;
                        }

                        &.closure {
                            background-image: url(../images/korken.svg);
                            background-size: 1.15rem;
                            background-position: left 1.2rem;
                        }

                        &.award {
                            background-image: url(../images/auszeichnung.svg);
                            background-size: 0.85rem;
                            background-position: left 1.1rem;
                        }
                    }

                    .product-info-bio,
                    .product-info-vegan {
                        padding: 1rem 0 0.5rem 0;
                    }
                }
            }
        }

        .product.media {
            @include make-col-ready;
            @include make-col(12);

            order: 1;

            @include media-breakpoint-up(sm) {
                @include make-col(5);
            }


            .gallery-next-area,
            .gallery-prev-area {
                display: none;
            }
        }

        h1 {
            color: $color-black;
            text-align: left;
            font-size: 1em;
            font-weight: bold;
            letter-spacing: 0.075em;
        }

        .product-view-description {
            @extend .mt-3;

            order: 4;
        }

        .product-view-downloads {
            @extend .mt-3;

            letter-spacing: 0.08rem;

            h4 {
                font-family: $primary-font;
                text-transform: uppercase;
                color: $color-black;
                font-size: 1rem;
            }

            .product-view-download-links a {
                @extend .download-links;

                color: $color-black;
                display: block;
                text-transform: uppercase;
                background-image: url(../images/downloads.svg);
                background-position: right 3px;
                background-size: 1.0rem;
                background-repeat: no-repeat;
                padding-right: 20px;
            }

            order: 3;
        }

        .product-view-winery-data {
            @extend .mt-4;

            @extend .mb-5;

            @extend .pt-3;

            @extend .pb-3;

            order: 5;
            background-color: $color-beige1;

            .winery-logo {
                max-height: 200px;
                max-width: 200px;
                width: 80%;
                height: auto;

                @include media-breakpoint-up(sm) {
                    max-height: 250px;
                    max-width: 250px;
                }
            }

            .winery-links-header {
                @extend .pb-1;

                font-size: 1.1rem;
                color: $color-red;
                font-weight: bold;
                letter-spacing: 0.08rem;
            }

            .winery-links {
                @include big-onpage-menu;

                font-family: $secondary-font;
            }
        }
    }
}

.b2b.catalog-product-view {
    .column.main {
        .product-info-main {
            .product-info-prices-wrapper {
                display: block;
            }
        }
    }

    .price-box {
        font-size: 1.2rem;

        &.price-offer {
            color: $color-red;
        }

        &.has-special {
            font-weight: 200;
            text-decoration: line-through;
        }

        .price-label {
            font-size: 0.8rem;
            font-weight: 200;
            text-transform: none;
        }
    }

    .to-cart-tool {
        // margin: 15px 0 5px 0;
        line-height: 1.5;

        > div {
            background-color: $color-beige1;
        }

        .field.qty {
            max-width: 60px;
            position: relative;

            input[type="number"] {
                background-color: $color-beige1;
                border: none;
                text-align: center;
                color: #000;
                opacity: 1;
                font-weight: 700;
            }

            // &::after {
            //     position: absolute;
            //     left: 0;
            //     right: 0;
            //     top: 0;
            //     bottom: 0;
            //     content: '';
            // }
        }
    }

    .box-tocart {
        margin-top: 25px;
        .actions {
            margin-top: 15px;

            .action.primary.tocart {
                width: 100%;
            }
        }
    }
}
