@mixin linear-gradient($direction, $color-stops...) {
    background: linear-gradient($direction, $color-stops);

    @if is-direction($direction) == false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }
}

// @mixin more-link-with-border() {
//     font-size: $h2-font-size;
//     text-transform: uppercase;
//     letter-spacing: 0.1rem;
//     padding: 10px 30px;
//     margin-top: 10%;
//     display: inline-block;
//     max-width: 200px;
//     min-width: 100px;
//     border-top: 1px solid $color-white;
//     border-bottom: 4px solid $color-white;
//     color: $color-white;
//
//     &:hover {
//         color: $color-beige-grey;
//     }
// }

@mixin more-link-with-border() {
    position: relative;
    font-size: $h2-font-size;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin: 10% auto 0;
    display: inline-block;
    max-width: 250px;
    min-width: 250px;
    color: $color-white;
    padding: 11px 34px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 90%;
        height: 1px;
        display: inline-block;
        transition: all 300ms ease;
        background-color: $color-white;
        transform: translateX(-50%);
    }

    &:after {
        top: initial;
        bottom: 0;
        height: 5px;
        transform: translateX(-50%);
    }

    &:hover {
        text-decoration: none;

        &:before,
        &:after {
            width: 100%;
        }
    }
}

@mixin secondary-font-red-bold() {
    font-family: $secondary-font;
    font-weight: bold;
    color: $color-red;
    text-transform: uppercase;
    padding-bottom: 0.8rem;
    letter-spacing: 0.1rem;
}

@mixin big-onpage-menu() {
    &-inner {
        border-top: 4px solid $color-black;

        a:last-child div {
            border-bottom: 4px solid $color-black;
        }
    }

    &-title {
        padding: 1rem 0;
        color: $color-black;
        font-size: 2.2rem;
        text-transform: uppercase;
        letter-spacing: 0.18rem;
        border-bottom: 1px solid $color-black;
    }

    a:hover {
        text-decoration: none;

        div {
            color: $color-red;
        }
    }
}

.download-links {
    @extend .pb-2;
    color: $color-black;
    display: block;
    text-transform: uppercase;
    background-image: url(../images/downloads.svg);
    background-position: right center;
    background-size: 1.0rem;
    background-repeat: no-repeat;
}
