.c-submenu {
    display: none;
    opacity: 0.85;
    position: fixed;
    top: 88px;
    left: 0;
    right: 0;
    background-color: $color-white;
    box-shadow: $header-shadow-lower;
    z-index: 990;

    @include media-breakpoint-up(md) {
        display: block;
    }

    .nav {
        justify-content: center;

        a {
            font-family: $secondary-font;
            font-size: 1.1em;
            color: $color-black;

            &:hover,
            &.active {
                color: $color-red;
            }
        }
    }
}
