// Footer
.l-footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: $color-red;
    color: $color-white;

    .row {
        padding-bottom: 50px;

        & > div {
            margin-top: 50px;

            h4,
            .h4 {
                font-family: $primary-font;
                text-align: center;
                color: $color-white;
                text-transform: uppercase;
                letter-spacing: 0.15rem;

                @include media-breakpoint-up(md) {
                    text-align: left;
                    margin-bottom: 0;
                    min-height: 3rem;
                }
            }

            p {
                text-align: center;
                font-family: $primary-font;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
        }
    }

    .menu-footer {
        color: $color-white;
        font-family: $primary-font;
        text-align: center;

        a {
            color: $color-white;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    h4 {
        font-size: 1rem;
    }

    ul > li {
        margin-bottom: 0;
    }
}

footer.page-footer {
    @extend .l-footer;

    .footer.content {
        @extend .container;
    }
}


