@charset "UTF-8";
#close-menu-icon .a,
#close-menu-icon .b {
  fill: none; }

#close-menu-icon .b {
  stroke: #b6a57c; }

#close-menu-icon .c,
#close-menu-icon .d {
  stroke: none; }

#close-menu-icon .d {
  fill: #b6a57c; }

.close-icon, .delete-icon, .plus-icon, .minus-icon {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  overflow: hidden;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -3px; }
  .close-icon:hover::before, .delete-icon:hover::before, .plus-icon:hover::before, .minus-icon:hover::before, .close-icon:hover::after, .delete-icon:hover::after, .plus-icon:hover::after, .minus-icon:hover::after {
    background: #bcbcbc; }
  .close-icon::before, .delete-icon::before, .plus-icon::before, .minus-icon::before, .close-icon::after, .delete-icon::after, .plus-icon::after, .minus-icon::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #bcbcbc; }
  .close-icon::before, .delete-icon::before, .plus-icon::before, .minus-icon::before {
    transform: rotate(45deg); }
  .close-icon::after, .delete-icon::after, .plus-icon::after, .minus-icon::after {
    transform: rotate(-45deg); }
  .close-icon.hairline::before, .hairline.delete-icon::before, .hairline.plus-icon::before, .hairline.minus-icon::before, .close-icon.hairline::after, .hairline.delete-icon::after, .hairline.plus-icon::after, .hairline.minus-icon::after {
    height: 1px; }
  .close-icon.in-dropdown, .in-dropdown.delete-icon, .in-dropdown.plus-icon, .in-dropdown.minus-icon {
    margin-top: 4px !important;
    font-weight: normal !important; }
    .close-icon.in-dropdown:hover::before, .in-dropdown.delete-icon:hover::before, .in-dropdown.plus-icon:hover::before, .in-dropdown.minus-icon:hover::before, .close-icon.in-dropdown:hover::after, .in-dropdown.delete-icon:hover::after, .in-dropdown.plus-icon:hover::after, .in-dropdown.minus-icon:hover::after {
      background: black !important;
      height: 1px !important; }
    .close-icon.in-dropdown::before, .in-dropdown.delete-icon::before, .in-dropdown.plus-icon::before, .in-dropdown.minus-icon::before, .close-icon.in-dropdown::after, .in-dropdown.delete-icon::after, .in-dropdown.plus-icon::after, .in-dropdown.minus-icon::after {
      content: '';
      position: absolute;
      height: 1px !important;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: black !important; }

.delete-icon::after, .delete-icon::before {
  width: 22px;
  height: 22px;
  background: black; }

.plus-icon {
  margin-right: 10px; }
  .plus-icon::after, .plus-icon::before {
    width: 22px;
    height: 22px;
    transform: rotate(0deg);
    background: black; }
  .plus-icon::after {
    transform: rotate(90deg);
    left: -2px; }

.minus-icon {
  margin-right: 10px; }
  .minus-icon::before {
    transform: rotate(0deg);
    background: black; }
  .minus-icon::after {
    transform: rotate(0deg);
    background: black; }

.check-mark {
  display: inline-block;
  transform: rotate(45deg);
  height: 16px;
  width: 8px;
  border-bottom: 2px solid #6f152f;
  border-right: 2px solid #6f152f; }
  .check-mark::after {
    display: none !important; }

.messages .success.message {
  background-color: #ebe7db;
  color: #171717; }
  .messages .success.message a {
    color: #6f152f; }

.messages .success.message > :first-child::before {
  color: #6f152f;
  line-height: 2.1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(23, 23, 23, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(23, 23, 23, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(23, 23, 23, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2, .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices, .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices, .catalog-product-view .column.main .product-info-main .product-item-info.details,
.my-2 {
  margin-top: 1rem !important; }

.mr-2, .c-social-links .c-social-link,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 2.5rem !important; }

.mt-3, .catalog-product-view .column.main .product-view-description, .catalog-product-view .column.main .product-view-downloads,
.my-3 {
  margin-top: 2.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2.5rem !important; }

.mb-3, .all-customer-block-titles, .login-container .block .block-title, .account .column.main .block .block-title,
.customer-account-login .column.main .block .block-title,
.customer-account-create .column.main .block .block-title,
.pellegrini-customer-downloads .column.main .block .block-title, .account .column.main .form .fieldset > .legend,
.account .column.main .form-address-edit .fieldset > .legend,
.customer-account-login .column.main .form .fieldset > .legend,
.customer-account-login .column.main .form-address-edit .fieldset > .legend,
.customer-account-create .column.main .form .fieldset > .legend,
.customer-account-create .column.main .form-address-edit .fieldset > .legend,
.pellegrini-customer-downloads .column.main .form .fieldset > .legend,
.pellegrini-customer-downloads .column.main .form-address-edit .fieldset > .legend, .account .column.main .block .box-actions,
.customer-account-login .column.main .block .box-actions,
.customer-account-create .column.main .block .box-actions,
.pellegrini-customer-downloads .column.main .block .box-actions,
.my-3 {
  margin-bottom: 2.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2.5rem !important; }

.m-4 {
  margin: 5rem !important; }

.mt-4, .catalog-product-view .column.main .product-view-winery-data, .account .page-title,
.customer-account-login .page-title,
.customer-account-create .page-title,
.pellegrini-customer-downloads .page-title, .checkout-cart-index .page-title,
.my-4 {
  margin-top: 5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 5rem !important; }

.mb-4, .account .page-title,
.customer-account-login .page-title,
.customer-account-create .page-title,
.pellegrini-customer-downloads .page-title, .checkout-cart-index .page-title,
.my-4 {
  margin-bottom: 5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 5rem !important; }

.m-5 {
  margin: 9rem !important; }

.mt-5,
.my-5 {
  margin-top: 9rem !important; }

.mr-5,
.mx-5 {
  margin-right: 9rem !important; }

.mb-5, .catalog-product-view .column.main .product-view-winery-data, .login-container, .account .column.main .form,
.account .column.main .form-address-edit,
.customer-account-login .column.main .form,
.customer-account-login .column.main .form-address-edit,
.customer-account-create .column.main .form,
.customer-account-create .column.main .form-address-edit,
.pellegrini-customer-downloads .column.main .form,
.pellegrini-customer-downloads .column.main .form-address-edit,
.my-5 {
  margin-bottom: 9rem !important; }

.ml-5,
.mx-5 {
  margin-left: 9rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1, .catalog-product-view .column.main .product-info-main .product-item-info.details,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1, .catalog-product-view .column.main .product-view-winery-data .winery-links-header, .catalog-category-view .product-item-offer .product-view-text.product-view-download-links.offer-link,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2, .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices, .catalog-product-view .column.main .product-info-main .description-content, .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2, .download-links, .catalog-product-view .column.main .product-view-downloads .product-view-download-links a, .account .download-link-list a,
.customer-account-login .download-link-list a,
.customer-account-create .download-link-list a,
.pellegrini-customer-downloads .download-link-list a, .catalog-product-view .column.main .product-info-main .description-content, .catalog-product-view .column.main .product-info-main .product-item-info.details .sku,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 2.5rem !important; }

.pt-3, .catalog-product-view .column.main .product-view-winery-data,
.py-3 {
  padding-top: 2.5rem !important; }

.pr-3,
.px-3 {
  padding-right: 2.5rem !important; }

.pb-3, .catalog-product-view .column.main .product-view-winery-data,
.py-3 {
  padding-bottom: 2.5rem !important; }

.pl-3,
.px-3 {
  padding-left: 2.5rem !important; }

.p-4 {
  padding: 5rem !important; }

.pt-4, .c-social-links .c-social-link,
.py-4 {
  padding-top: 5rem !important; }

.pr-4,
.px-4 {
  padding-right: 5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 5rem !important; }

.pl-4,
.px-4 {
  padding-left: 5rem !important; }

.p-5 {
  padding: 9rem !important; }

.pt-5,
.py-5 {
  padding-top: 9rem !important; }

.pr-5,
.px-5 {
  padding-right: 9rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 9rem !important; }

.pl-5,
.px-5 {
  padding-left: 9rem !important; }

.m-n1 {
  margin: -0.5rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important; }

.m-n2 {
  margin: -1rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -1rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important; }

.m-n3 {
  margin: -2.5rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -2.5rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -2.5rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -2.5rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -2.5rem !important; }

.m-n4 {
  margin: -5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -5rem !important; }

.m-n5 {
  margin: -9rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -9rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -9rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -9rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -9rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .m-sm-3 {
    margin: 2.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2.5rem !important; }
  .m-sm-4 {
    margin: 5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 5rem !important; }
  .m-sm-5 {
    margin: 9rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 9rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 9rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 9rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 9rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .p-sm-3 {
    padding: 2.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2.5rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2.5rem !important; }
  .p-sm-4 {
    padding: 5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 5rem !important; }
  .p-sm-5 {
    padding: 9rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 9rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 9rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 9rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 9rem !important; }
  .m-sm-n1 {
    margin: -0.5rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.5rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.5rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.5rem !important; }
  .m-sm-n2 {
    margin: -1rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1rem !important; }
  .m-sm-n3 {
    margin: -2.5rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -2.5rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -2.5rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -2.5rem !important; }
  .m-sm-n4 {
    margin: -5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -5rem !important; }
  .m-sm-n5 {
    margin: -9rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -9rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -9rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -9rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -9rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 2.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2.5rem !important; }
  .m-md-4 {
    margin: 5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 5rem !important; }
  .m-md-5 {
    margin: 9rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 9rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 9rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 9rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 9rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 2.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2.5rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2.5rem !important; }
  .p-md-4 {
    padding: 5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 5rem !important; }
  .p-md-5 {
    padding: 9rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 9rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 9rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 9rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 9rem !important; }
  .m-md-n1 {
    margin: -0.5rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5rem !important; }
  .m-md-n2 {
    margin: -1rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1rem !important; }
  .m-md-n3 {
    margin: -2.5rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -2.5rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -2.5rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -2.5rem !important; }
  .m-md-n4 {
    margin: -5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -5rem !important; }
  .m-md-n5 {
    margin: -9rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -9rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -9rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -9rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -9rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 2.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2.5rem !important; }
  .m-lg-4 {
    margin: 5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 5rem !important; }
  .m-lg-5 {
    margin: 9rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 9rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 9rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 9rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 9rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 2.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2.5rem !important; }
  .p-lg-4 {
    padding: 5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 5rem !important; }
  .p-lg-5 {
    padding: 9rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 9rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 9rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 9rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 9rem !important; }
  .m-lg-n1 {
    margin: -0.5rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5rem !important; }
  .m-lg-n2 {
    margin: -1rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1rem !important; }
  .m-lg-n3 {
    margin: -2.5rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -2.5rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -2.5rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -2.5rem !important; }
  .m-lg-n4 {
    margin: -5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -5rem !important; }
  .m-lg-n5 {
    margin: -9rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -9rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -9rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -9rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -9rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 2.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2.5rem !important; }
  .m-xl-4 {
    margin: 5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 5rem !important; }
  .m-xl-5 {
    margin: 9rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 9rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 9rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 9rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 9rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 2.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2.5rem !important; }
  .p-xl-4 {
    padding: 5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 5rem !important; }
  .p-xl-5 {
    padding: 9rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 9rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 9rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 9rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 9rem !important; }
  .m-xl-n1 {
    margin: -0.5rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5rem !important; }
  .m-xl-n2 {
    margin: -1rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1rem !important; }
  .m-xl-n3 {
    margin: -2.5rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -2.5rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -2.5rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -2.5rem !important; }
  .m-xl-n4 {
    margin: -5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -5rem !important; }
  .m-xl-n5 {
    margin: -9rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -9rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -9rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -9rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -9rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.5rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.5rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.5rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.5rem !important; }
  .m-xxl-2 {
    margin: 1rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 1rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 1rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 1rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 1rem !important; }
  .m-xxl-3 {
    margin: 2.5rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 2.5rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 2.5rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 2.5rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 2.5rem !important; }
  .m-xxl-4 {
    margin: 5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 5rem !important; }
  .m-xxl-5 {
    margin: 9rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 9rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 9rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 9rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 9rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.5rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.5rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.5rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.5rem !important; }
  .p-xxl-2 {
    padding: 1rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 1rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 1rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 1rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 1rem !important; }
  .p-xxl-3 {
    padding: 2.5rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 2.5rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 2.5rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 2.5rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 2.5rem !important; }
  .p-xxl-4 {
    padding: 5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 5rem !important; }
  .p-xxl-5 {
    padding: 9rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 9rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 9rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 9rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 9rem !important; }
  .m-xxl-n1 {
    margin: -0.5rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.5rem !important; }
  .m-xxl-n2 {
    margin: -1rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -1rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -1rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -1rem !important; }
  .m-xxl-n3 {
    margin: -2.5rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -2.5rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -2.5rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -2.5rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -2.5rem !important; }
  .m-xxl-n4 {
    margin: -5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -5rem !important; }
  .m-xxl-n5 {
    margin: -9rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -9rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -9rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -9rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -9rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(23, 23, 23, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, footer.page-footer .footer.content {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #171717; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(23, 23, 23, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, footer.page-footer .footer.content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, footer.page-footer .footer.content {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, footer.page-footer .footer.content {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, footer.page-footer .footer.content {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, footer.page-footer .footer.content {
      max-width: 1140px; } }
  @media (min-width: 1600px) {
    .container, footer.page-footer .footer.content {
      max-width: 1560px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(23, 23, 23, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(23, 23, 23, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(23, 23, 23, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(10, 10, 10, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(10, 10, 10, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(23, 23, 23, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(23, 23, 23, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(23, 23, 23, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(23, 23, 23, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(23, 23, 23, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #171717;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #171717;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container, footer.page-footer .navbar > .footer.content,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, footer.page-footer .navbar-expand-sm > .footer.content,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container, footer.page-footer .navbar-expand-sm > .footer.content,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, footer.page-footer .navbar-expand-md > .footer.content,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, footer.page-footer .navbar-expand-md > .footer.content,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, footer.page-footer .navbar-expand-lg > .footer.content,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container, footer.page-footer .navbar-expand-lg > .footer.content,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, footer.page-footer .navbar-expand-xl > .footer.content,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container, footer.page-footer .navbar-expand-xl > .footer.content,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container, footer.page-footer .navbar-expand-xxl > .footer.content,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container, footer.page-footer .navbar-expand-xxl > .footer.content,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, footer.page-footer .navbar-expand > .footer.content,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container, footer.page-footer .navbar-expand > .footer.content,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(23, 23, 23, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(23, 23, 23, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(23, 23, 23, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(23, 23, 23, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(23, 23, 23, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(23, 23, 23, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(23, 23, 23, 0.5);
  border-color: rgba(23, 23, 23, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(23, 23, 23, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(23, 23, 23, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(23, 23, 23, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(23, 23, 23, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(23, 23, 23, 0.15);
  border-radius: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1600px) {
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropdown-menu-left {
  right: auto;
  left: 0; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; } }

@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0 - 1px);
    border-top-right-radius: calc(0 - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0 - 1px);
    border-bottom-left-radius: calc(0 - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

/*!
 * Bootstrap-select v1.13.5 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2018 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */
select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle; }

.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  z-index: 1;
  text-align: right;
  white-space: nowrap; }

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
  color: rgba(255, 255, 255, 0.5); }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2; }

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.bootstrap-select.is-invalid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
  border-color: #28a745; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px; }

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }

:not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  z-index: auto; }

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }

.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }

.bootstrap-select.disabled:focus,
.bootstrap-select > .disabled:focus {
  outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select .dropdown-toggle:before {
  content: '';
  display: inline-block; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left; }

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .dropdown-menu > .inner:focus {
  outline: none !important; }

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select .dropdown-menu li {
  position: relative; }

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important; }

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em; }

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/*# sourceMappingURL=bootstrap-select.css.map */
img.lazyload {
  opacity: 0.3;
  transition: opacity 0.3s ease-in 0s; }

.d-none {
  display: none;
  width: 0;
  height: 0; }

.dummy-country-start {
  background-color: #ebe7db;
  height: 300px; }

.thin-line {
  border-top: 1px solid #171717; }

.download-links, .catalog-product-view .column.main .product-view-downloads .product-view-download-links a, .account .download-link-list a,
.customer-account-login .download-link-list a,
.customer-account-create .download-link-list a,
.pellegrini-customer-downloads .download-link-list a {
  color: #171717;
  display: block;
  text-transform: uppercase;
  background-image: url(../images/downloads.svg);
  background-position: right center;
  background-size: 1.0rem;
  background-repeat: no-repeat; }

@font-face {
  font-family: 'Publico Headline';
  src: url("PublicoHeadline-Roman-Web.eot");
  src: url("../fonts/PublicoHeadline/PublicoHeadline-Roman-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/PublicoHeadline/PublicoHeadline-Roman-Web.woff2") format("woff2"), url("../fonts/PublicoHeadline/PublicoHeadline-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-Bold.woff2") format("woff2"), url("../fonts/SourceSansPro/SourceSansPro-Bold.woff") format("woff"), url("../fonts/SourceSansPro/SourceSansPro-Bold.otf") format("opentype");
  font-style: bold;
  font-weight: 700; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-SemiBold.woff2") format("woff2"), url("../fonts/SourceSansPro/SourceSansPro-SemiBold.woff") format("woff"), url("../fonts/SourceSansPro/SourceSansPro-SemiBold.otf") format("opentype");
  font-style: bold;
  font-weight: 600; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-Regular.woff2") format("woff2"), url("../fonts/SourceSansPro/SourceSansPro-Regular.woff") format("woff"), url("../fonts/SourceSansPro/SourceSansPro-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Source Code Pro";
  src: url("../fonts/SourceCodePro/SourceCodePro-Regular.woff2") format("woff2"), url("../fonts/SourceCodePro/SourceCodePro-Regular.woff") format("woff"), url("../fonts/SourceCodePro/SourceCodePro-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

html {
  -webkit-font-smoothing: antialiased; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 1.25 / 1), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: subpixel-antialiased; } }

body {
  font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif; }

h1,
h2 {
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  color: #6f152f;
  margin-top: 2rem;
  font-size: 2rem;
  letter-spacing: 0.22rem;
  line-height: 1.3; }

main > h1:first-child {
  margin-top: 144px;
  margin-bottom: 0; }

main > h1 ~ h2 {
  margin-top: 0;
  margin-bottom: 130px; }

h2 {
  font-size: 1.15rem;
  letter-spacing: 0.15rem;
  margin-top: 1.2rem;
  color: #171717; }

h3,
h4 {
  font-family: "Publico Headline", serif;
  font-weight: bold;
  color: #6f152f;
  line-height: 1.3; }

h4 {
  line-height: 32px; }

p {
  line-height: 1.8rem;
  margin-bottom: 2rem; }

a {
  color: #6f152f; }
  a:hover {
    color: #841938;
    text-decoration: none; }

b,
strong {
  font-weight: bold; }

html {
  position: relative;
  min-height: 100%; }

html {
  font-size: 1rem; }

@media (min-width: 576px) {
  html {
    font-size: 1.05rem; } }

@media (min-width: 992px) {
  html {
    font-size: 1.1rem; } }

@media (min-width: 1200px) {
  html {
    font-size: 1.3rem; } }

.l-body, .page-wrapper {
  background-color: #fff;
  color: #171717;
  padding-top: 143px; }

body.b2b .page-wrapper {
  padding-top: 200px; }

.nav-sections, .breadcrumbs {
  display: none; }

main.page-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 540px; }
  @media (min-width: 768px) {
    main.page-main {
      max-width: 720px; } }
  @media (min-width: 992px) {
    main.page-main {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    main.page-main {
      max-width: 1140px; } }
  @media (min-width: 1600px) {
    main.page-main {
      max-width: 1560px; } }

.l-header, header.page-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  color: #171717;
  box-shadow: 0 1px 1px rgba(200, 200, 200, 0.6) !important;
  height: 88px; }
  .l-header .c-hamburger, header.page-header .c-hamburger {
    position: relative; }
    .l-header .c-hamburger #menu-icon, header.page-header .c-hamburger #menu-icon {
      height: 40px;
      width: 40px;
      margin: 24px 0;
      cursor: pointer; }
    .l-header .c-hamburger .menu-label, header.page-header .c-hamburger .menu-label {
      font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
      font-size: 1rem;
      text-transform: uppercase;
      position: absolute;
      left: 48px;
      top: 50%;
      transform: translateY(-50%);
      color: #b6a57c;
      cursor: pointer; }
    @media (max-width: 767.98px) {
      .l-header .c-hamburger .menu-label, header.page-header .c-hamburger .menu-label {
        display: none; } }
  .l-header #logo, header.page-header #logo {
    width: 42.66px;
    height: 34px;
    margin: 27px 0; }
  .l-header #account-link, header.page-header #account-link {
    width: 28.12px;
    height: 28.28px;
    margin: 28px 0;
    position: relative; }
    .l-header #account-link .c-social-link, header.page-header #account-link .c-social-link {
      padding-top: 0 !important; }
      .l-header #account-link .c-social-link a, header.page-header #account-link .c-social-link a {
        position: absolute;
        top: 0;
        right: 30px; }
        .l-header #account-link .c-social-link a svg, header.page-header #account-link .c-social-link a svg {
          height: 30px;
          width: auto; }
      .l-header #account-link .c-social-link.c-social-links-instagram a, header.page-header #account-link .c-social-link.c-social-links-instagram a {
        right: 64px; }
    @media (min-width: 576px) {
      .l-header #account-link .c-social-link a, header.page-header #account-link .c-social-link a {
        right: 33px; }
      .l-header #account-link .c-social-link.c-social-links-instagram a, header.page-header #account-link .c-social-link.c-social-links-instagram a {
        right: 70px; } }
    @media (min-width: 768px) {
      .l-header #account-link .c-social-link a, header.page-header #account-link .c-social-link a {
        right: 114px; }
      .l-header #account-link .c-social-link.c-social-links-instagram a, header.page-header #account-link .c-social-link.c-social-links-instagram a {
        right: 153px; } }
    @media (min-width: 992px) {
      .l-header #account-link .c-social-link a, header.page-header #account-link .c-social-link a {
        right: 122px; }
      .l-header #account-link .c-social-link.c-social-links-instagram a, header.page-header #account-link .c-social-link.c-social-links-instagram a {
        right: 162px; } }
    @media (min-width: 1200px) {
      .l-header #account-link .c-social-link a, header.page-header #account-link .c-social-link a {
        right: 140px; }
      .l-header #account-link .c-social-link.c-social-links-instagram a, header.page-header #account-link .c-social-link.c-social-links-instagram a {
        right: 180px; } }
  .l-header .account-links, header.page-header .account-links {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    text-align: right; }
    .l-header .account-links .customer-link, header.page-header .account-links .customer-link {
      vertical-align: middle;
      line-height: 1rem;
      display: inline-block; }
      .l-header .account-links .customer-link a, header.page-header .account-links .customer-link a {
        font-size: 1.0rem;
        text-transform: uppercase;
        color: #b6a57c; }
        .l-header .account-links .customer-link a svg, header.page-header .account-links .customer-link a svg {
          height: 30px; }
      .l-header .account-links .customer-link .minicart-wrapper, header.page-header .account-links .customer-link .minicart-wrapper {
        margin-top: 0;
        margin-left: 15px; }
        .l-header .account-links .customer-link .minicart-wrapper .action.showcart::before, header.page-header .account-links .customer-link .minicart-wrapper .action.showcart::before {
          content: ' ';
          color: #b6a57c;
          height: 30px;
          width: 30px;
          background-image: url(../images/Cart-beige.svg);
          background-size: contain;
          background-repeat: no-repeat; }
        .l-header .account-links .customer-link .minicart-wrapper .action.showcart .counter.qty, header.page-header .account-links .customer-link .minicart-wrapper .action.showcart .counter.qty {
          background-color: #b6a57c;
          height: 16px;
          line-height: 14px; }
          .l-header .account-links .customer-link .minicart-wrapper .action.showcart .counter.qty .counter-number, header.page-header .account-links .customer-link .minicart-wrapper .action.showcart .counter.qty .counter-number {
            font-size: 0.8rem; }
          .l-header .account-links .customer-link .minicart-wrapper .action.showcart .counter.qty:after, header.page-header .account-links .customer-link .minicart-wrapper .action.showcart .counter.qty:after {
            content: 'Fl.';
            font-size: 0.8rem; }
  .l-header a, header.page-header a {
    color: #171717;
    padding: 0; }
    .l-header a:hover, header.page-header a:hover {
      color: rgba(23, 23, 23, 0.7); }

.l-footer, footer.page-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #6f152f;
  color: #fff; }
  .l-footer .row, footer.page-footer .row {
    padding-bottom: 50px; }
    .l-footer .row > div, footer.page-footer .row > div {
      margin-top: 50px; }
      .l-footer .row > div h4, footer.page-footer .row > div h4,
      .l-footer .row > div .h4,
      footer.page-footer .row > div .h4 {
        font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.15rem; }
        @media (min-width: 768px) {
          .l-footer .row > div h4, footer.page-footer .row > div h4,
          .l-footer .row > div .h4,
          footer.page-footer .row > div .h4 {
            text-align: left;
            margin-bottom: 0;
            min-height: 3rem; } }
      .l-footer .row > div p, footer.page-footer .row > div p {
        text-align: center;
        font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif; }
        @media (min-width: 768px) {
          .l-footer .row > div p, footer.page-footer .row > div p {
            text-align: left; } }
  .l-footer .menu-footer, footer.page-footer .menu-footer {
    color: #fff;
    font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
    text-align: center; }
    .l-footer .menu-footer a, footer.page-footer .menu-footer a {
      color: #fff;
      padding: 0; }
      .l-footer .menu-footer a:hover, footer.page-footer .menu-footer a:hover {
        text-decoration: underline; }
    @media (min-width: 768px) {
      .l-footer .menu-footer, footer.page-footer .menu-footer {
        text-align: left; } }
  .l-footer h4, footer.page-footer h4 {
    font-size: 1rem; }
  .l-footer ul > li, footer.page-footer ul > li {
    margin-bottom: 0; }

.l-headervisual .container, .l-headervisual footer.page-footer .footer.content, footer.page-footer .l-headervisual .footer.content {
  max-width: 691.2px; }
  @media (min-width: 768px) {
    .l-headervisual .container, .l-headervisual footer.page-footer .footer.content, footer.page-footer .l-headervisual .footer.content {
      max-width: 814.08px; } }
  @media (min-width: 992px) {
    .l-headervisual .container, .l-headervisual footer.page-footer .footer.content, footer.page-footer .l-headervisual .footer.content {
      max-width: 1051.52px; } }
  @media (min-width: 1200px) {
    .l-headervisual .container, .l-headervisual footer.page-footer .footer.content, footer.page-footer .l-headervisual .footer.content {
      max-width: 1272px; } }
  @media (min-width: 1600px) {
    .l-headervisual .container, .l-headervisual footer.page-footer .footer.content, footer.page-footer .l-headervisual .footer.content {
      max-width: 1696px; } }

#close-menu-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 24px 0; }

.c-menu-overlay {
  z-index: 1060;
  width: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  /* Footermenu Inhalte im Hauptmenu */ }
  .c-menu-overlay--close {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
  .c-menu-overlay .c-menu-overlay-bg {
    background-color: #fff;
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50vw; }
  .c-menu-overlay .c-menu-container {
    height: 100vh;
    pointer-events: none; }
  .c-menu-overlay.inactive {
    display: none; }
  .c-menu-overlay .c-mainmenu {
    text-align: center;
    font-family: "Publico Headline", serif;
    text-transform: uppercase;
    pointer-events: initial; }
    @media (min-width: 768px) {
      .c-menu-overlay .c-mainmenu {
        text-align: left; } }
    .c-menu-overlay .c-mainmenu .navbar {
      padding-left: 0; }
    .c-menu-overlay .c-mainmenu ul.navbar-nav {
      width: 100%; }
      .c-menu-overlay .c-mainmenu ul.navbar-nav li {
        margin-bottom: 0; }
    .c-menu-overlay .c-mainmenu a.nav-link {
      font-size: 1.6rem;
      letter-spacing: 0.15rem;
      color: #171717; }
      .c-menu-overlay .c-mainmenu a.nav-link:hover, .c-menu-overlay .c-mainmenu a.nav-link.active {
        color: #6f152f; }
  .c-menu-overlay .footermenu {
    color: #171717;
    font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
    text-align: left;
    pointer-events: initial; }
    .c-menu-overlay .footermenu a {
      color: #171717;
      padding: 0; }
      .c-menu-overlay .footermenu a:hover {
        text-decoration: underline; }
    .c-menu-overlay .footermenu ul > li {
      margin-bottom: 0; }
  .c-menu-overlay .c-menu-container-inner {
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.16) !important; }
    @media (min-width: 768px) {
      .c-menu-overlay .c-menu-container-inner {
        width: 60%; } }

.c-submenu {
  display: none;
  opacity: 0.85;
  position: fixed;
  top: 88px;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(200, 200, 200, 0.6) !important;
  z-index: 990; }
  @media (min-width: 768px) {
    .c-submenu {
      display: block; } }
  .c-submenu .nav {
    justify-content: center; }
    .c-submenu .nav a {
      font-family: "Publico Headline", serif;
      font-size: 1.1em;
      color: #171717; }
      .c-submenu .nav a:hover, .c-submenu .nav a.active {
        color: #6f152f; }

.button,
button,
button.action.primary,
a.action.primary {
  background-color: #171717;
  color: #fff;
  font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.085rem;
  font-size: 1.0rem;
  font-weight: 200;
  border: none; }
  .button:hover, .button:focus, .button:active,
  button:hover,
  button:focus,
  button:active,
  button.action.primary:hover,
  button.action.primary:focus,
  button.action.primary:active,
  a.action.primary:hover,
  a.action.primary:focus,
  a.action.primary:active {
    background-color: #6f152f;
    color: #fff;
    border: none; }

.customer-account-login .column.main .actions-toolbar .primary a.action.primary,
.column.main .actions-toolbar .primary a.action.primary {
  font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.085rem;
  color: #fff !important;
  background-image: none !important;
  font-size: 1.0rem;
  border: none;
  border-radius: 0; }
  .customer-account-login .column.main .actions-toolbar .primary a.action.primary:hover, .customer-account-login .column.main .actions-toolbar .primary a.action.primary:focus, .customer-account-login .column.main .actions-toolbar .primary a.action.primary:active,
  .column.main .actions-toolbar .primary a.action.primary:hover,
  .column.main .actions-toolbar .primary a.action.primary:focus,
  .column.main .actions-toolbar .primary a.action.primary:active {
    background-color: #6f152f;
    color: #fff !important;
    border: none; }

.actions-toolbar .primary,
.actions-toolbar .secondary {
  margin-right: 15px; }

.c-social-links {
  text-align: center; }
  @media (min-width: 768px) {
    .c-social-links {
      text-align: left; } }
  .c-social-links .c-social-link {
    display: inline-block; }
    .c-social-links .c-social-link a {
      display: inline-block;
      transition: opacity 300ms ease-in; }
      .c-social-links .c-social-link a:hover {
        opacity: 0.8; }
    .c-social-links .c-social-link svg {
      width: 50px;
      height: 50px; }

.ce-headerslider-slider .slick-dots {
  bottom: 5%; }

.ce-headerslider-slider .ce-headerslider-slide {
  z-index: 5;
  position: relative; }
  .ce-headerslider-slider .ce-headerslider-slide picture img {
    width: 100%; }
  .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-darken {
    background-color: #171717;
    opacity: 0.4;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    text-align: center;
    padding-top: 20%;
    text-transform: uppercase;
    font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif; }
    .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-date {
      font-size: 0.7rem;
      font-weight: bold;
      font-family: "Publico Headline", serif; }
    .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-header {
      font-size: 2rem;
      letter-spacing: 0.22rem;
      max-width: 80%;
      margin: 0 auto; }
    .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-subheader {
      font-size: 0.8rem;
      font-family: "Publico Headline", serif; }
    .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-link {
      position: relative;
      font-size: 1.15rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      margin: 10% auto 0;
      display: inline-block;
      max-width: 250px;
      min-width: 250px;
      color: #fff;
      padding: 11px 34px; }
      .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-link:before, .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-link:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 90%;
        height: 1px;
        display: inline-block;
        transition: all 300ms ease;
        background-color: #fff;
        transform: translateX(-50%); }
      .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-link:after {
        top: initial;
        bottom: 0;
        height: 5px;
        transform: translateX(-50%); }
      .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-link:hover {
        text-decoration: none; }
        .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-link:hover:before, .ce-headerslider-slider .ce-headerslider-slide .ce-headerslider-textelements .ce-headerslider-link:hover:after {
          width: 100%; }

.we-region-data {
  border-bottom: 1px solid #171717; }
  .we-region-data .we-region-content-row .we-icon {
    height: 200px;
    position: relative; }
    .we-region-data .we-region-content-row .we-icon svg {
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 10;
      vertical-align: bottom;
      max-width: 80px; }
  .we-region-data .we-region-content-row .we-region-content {
    border-top: 1px solid #171717;
    padding-top: 10px; }

.we-winery-data {
  background-color: #ebe7db;
  margin: 60px 0;
  padding: 50px 15px; }
  @media (min-width: 768px) {
    .we-winery-data {
      padding-left: 0;
      padding-right: 0; } }
  .we-winery-data h4 {
    color: #171717;
    text-transform: uppercase; }
  .we-winery-data .we-winery-thinline {
    border-bottom: 1px solid #171717; }
  .we-winery-data .we-winery-fatline {
    border-bottom: 4px solid #171717;
    margin-bottom: 0.8rem; }
  .we-winery-data .we-winery-location {
    font-family: "Publico Headline", serif;
    font-weight: bold;
    color: #6f152f;
    text-transform: uppercase;
    padding-bottom: 0.8rem;
    letter-spacing: 0.1rem; }

.we-structure {
  margin: 120px 0; }
  .we-structure-country-title {
    padding: 1rem 0;
    border-top: 4px solid #171717;
    color: #171717;
    font-size: 2.2rem; }
  .we-structure-country a:hover {
    text-decoration: none; }
    .we-structure-country a:hover div {
      color: #6f152f; }
  .we-structure-country .we-country-last {
    border-bottom: 4px solid #171717; }
  .we-structure-region {
    padding: 15px 0; }
    .we-structure-region-title {
      border-top: 1px solid #171717;
      font-family: "Publico Headline", serif;
      font-weight: 700;
      font-size: 1.2rem;
      color: #6f152f; }
  .we-structure-winery-title {
    text-align: center; }
    .we-structure-winery-title img {
      height: auto;
      min-width: 60%;
      max-width: 100%;
      padding: 15px 0; }

.we-country-list {
  margin-top: 120px;
  margin-bottom: 120px; }
  .we-country-list-inner {
    border-top: 4px solid #171717; }
    .we-country-list-inner a:last-child div {
      border-bottom: 4px solid #171717; }
  .we-country-list-title {
    padding: 1rem 0;
    color: #171717;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 0.18rem;
    border-bottom: 1px solid #171717; }
  .we-country-list a:hover {
    text-decoration: none; }
    .we-country-list a:hover div {
      color: #6f152f; }

.we-country-singleview #regioncounter {
  width: 10rem; }

/*!
 * Bootstrap-select v1.13.5 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2018 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */
select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle; }

.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  z-index: 1;
  text-align: right;
  white-space: nowrap; }

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
  color: rgba(255, 255, 255, 0.5); }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2; }

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.bootstrap-select.is-invalid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
  border-color: #28a745; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px; }

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }

:not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  z-index: auto; }

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }

.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }

.bootstrap-select.disabled:focus,
.bootstrap-select > .disabled:focus {
  outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select .dropdown-toggle:before {
  content: '';
  display: inline-block; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left; }

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .dropdown-menu > .inner:focus {
  outline: none !important; }

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select .dropdown-menu li {
  position: relative; }

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important; }

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em; }

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/*# sourceMappingURL=bootstrap-select.css.map */
.slick-dots li {
  width: 34px; }
  .slick-dots li button:before {
    content: "";
    width: 28px;
    height: 6px;
    background-color: #6f152f;
    opacity: 1; }

.slick-dots li.slick-active button:before {
  background-color: #dedacf; }

.catalog-category-view .wine-counter {
  display: none; }

.catalog-category-view .page-title-wrapper {
  display: none; }

.catalog-category-view .product-items {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 20px;
  list-style: none; }
  .catalog-category-view .product-items .product-item {
    z-index: 40;
    background-color: #fff;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    .catalog-category-view .product-items .product-item .product-item-details-overlay {
      position: absolute;
      z-index: 30;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      display: none; }
      .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link {
        position: relative;
        font-size: 1.15rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        margin: 10% auto 0;
        display: inline-block;
        max-width: 250px;
        min-width: 250px;
        color: #fff;
        padding: 11px 34px;
        z-index: 30;
        color: #171717;
        margin-top: 40%; }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:before, .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 90%;
          height: 1px;
          display: inline-block;
          transition: all 300ms ease;
          background-color: #fff;
          transform: translateX(-50%); }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:after {
          top: initial;
          bottom: 0;
          height: 5px;
          transform: translateX(-50%); }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:hover {
          text-decoration: none; }
          .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:hover:before, .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:hover:after {
            width: 100%; }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:after, .catalog-category-view .product-items .product-item .product-item-details-overlay .product-item-more-link:before {
          background-color: #171717; }
      .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices {
        border-top: none;
        border-bottom: none; }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices .price-box-in-list {
          text-align: center;
          font-weight: bold;
          font-size: 1.2rem; }
          .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices .price-box-in-list .price-label {
            display: none; }
          .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices .price-box-in-list.price-final_price-in-list:before {
            content: '';
            font-size: 0.8rem;
            font-weight: normal;
            text-transform: none; }
          .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices .price-box-in-list.price-tier-price-in-list:before {
            content: '';
            font-size: 0.8rem;
            font-weight: normal;
            text-transform: none; }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .product-info-prices .price-container {
          padding: 0; }
      .catalog-category-view .product-items .product-item .product-item-details-overlay .to-cart-tool {
        margin: 15px 0 5px 0; }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .to-cart-tool > div {
          background-color: #ebe7db; }
        .catalog-category-view .product-items .product-item .product-item-details-overlay .to-cart-tool .field.qty {
          max-width: 60px;
          position: relative; }
          .catalog-category-view .product-items .product-item .product-item-details-overlay .to-cart-tool .field.qty input[type="number"] {
            background-color: #ebe7db;
            border: none;
            text-align: center;
            color: #000;
            opacity: 1;
            font-weight: 700; }
    .catalog-category-view .product-items .product-item:hover {
      z-index: 10; }
      .catalog-category-view .product-items .product-item:hover .product-item-details-overlay {
        display: block; }
    .catalog-category-view .product-items .product-item:hover:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      background-color: #fff;
      opacity: 0.8; }
    .catalog-category-view .product-items .product-item .new-item,
    .catalog-category-view .product-items .product-item .new-equipment {
      width: 100px;
      height: 100px;
      position: absolute;
      right: 15px;
      top: 15px;
      background-image: url(../images/neu-im-sortiment.svg);
      background-size: 100px 100px;
      background-repeat: no-repeat;
      background-position: left top; }
    .catalog-category-view .product-items .product-item .new-equipment {
      background-image: url(../images/neue-ausstattung.svg); }
    .catalog-category-view .product-items .product-item .icons-in-list-left {
      width: 75px;
      position: absolute;
      left: 15px;
      top: 15px; }
      .catalog-category-view .product-items .product-item .icons-in-list-left .listout,
      .catalog-category-view .product-items .product-item .icons-in-list-left .vintageout,
      .catalog-category-view .product-items .product-item .icons-in-list-left .wineryonly {
        width: 75px;
        height: 75px;
        margin-bottom: 5px;
        background-image: url(../images/ausgelistet.svg);
        background-size: 75px 75px;
        background-repeat: no-repeat;
        background-position: left top; }
      .catalog-category-view .product-items .product-item .icons-in-list-left .listout {
        background-image: url(../images/ausgelistet.svg); }
      .catalog-category-view .product-items .product-item .icons-in-list-left .vintageout {
        background-image: url(../images/jahrgang_ausverkauft.svg); }
      .catalog-category-view .product-items .product-item .icons-in-list-left .wineryonly {
        background-image: url(../images/nur_ab_weingut.svg); }
    .catalog-category-view .product-items .product-item .is-bio {
      width: 40px;
      height: 40px; }
    .catalog-category-view .product-items .product-item .product-image-container {
      position: relative; }
      .catalog-category-view .product-items .product-item .product-image-container .product-info-bio {
        position: absolute;
        left: 0;
        bottom: 5px; }
      .catalog-category-view .product-items .product-item .product-image-container .product-info-vegan {
        position: absolute;
        right: 0;
        bottom: 5px; }
      .catalog-category-view .product-items .product-item .product-image-container .product-image-wrapper {
        padding-bottom: 0; }
        .catalog-category-view .product-items .product-item .product-image-container .product-image-wrapper .product-image-photo {
          max-width: 100%;
          padding-bottom: 0.7rem; }
    @media (min-width: 576px) {
      .catalog-category-view .product-items .product-item {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .catalog-category-view .product-items .product-item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
    @media (min-width: 1600px) {
      .catalog-category-view .product-items .product-item {
        flex: 0 0 25%;
        max-width: 25%; } }
    .catalog-category-view .product-items .product-item-details {
      border-top: 1px solid #171717; }
    .catalog-category-view .product-items .product-item-info {
      border-bottom: 4px solid #171717;
      height: 100%;
      margin: 0 0 0.5rem 0;
      padding: 4rem 0 2rem 0; }
      .catalog-category-view .product-items .product-item-info .product-item-appellation,
      .catalog-category-view .product-items .product-item-info .product-item-sku-winery {
        text-transform: uppercase;
        font-size: 0.8em;
        padding: 0.5rem 0 0.5rem 0;
        letter-spacing: 0.075em;
        line-height: 1.2rem; }
      .catalog-category-view .product-items .product-item-info .product-item-name .product-item-link {
        text-transform: uppercase;
        display: block;
        line-height: 1.1em;
        font-weight: bold;
        font-size: 1em;
        color: #171717;
        letter-spacing: 0.075em; }
    .catalog-category-view .product-items .product-item .price-details {
      display: none; }
    .catalog-category-view .product-items .product-item .price-box {
      position: absolute;
      bottom: 12px;
      right: 15px;
      font-size: 1.2em;
      font-weight: 700;
      letter-spacing: 0.02em; }
      .catalog-category-view .product-items .product-item .price-box .minimal-price-in-list .price-label {
        font-size: 0.8rem;
        font-weight: normal; }
      .catalog-category-view .product-items .product-item .price-box .old-price .price-label,
      .catalog-category-view .product-items .product-item .price-box .special-price .price-label {
        display: none; }
      .catalog-category-view .product-items .product-item .price-box .old-price .price {
        font-weight: 200;
        text-decoration: line-through; }
      .catalog-category-view .product-items .product-item .price-box .special-price .price {
        color: #6f152f; }
    .catalog-category-view .product-items .product-item .product-item-capacity {
      position: absolute;
      bottom: 16px;
      left: 15px;
      font-size: 0.8em;
      font-weight: normal; }
      .catalog-category-view .product-items .product-item .product-item-capacity:after {
        content: 'l';
        padding-left: 0.2em; }

.catalog-product-view .product-view-winery-link {
  position: relative;
  font-size: 1.15rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 10% auto 0;
  display: inline-block;
  max-width: 250px;
  min-width: 250px;
  color: #fff;
  padding: 11px 34px;
  max-width: 100%;
  min-width: 100%;
  color: #171717; }
  .catalog-product-view .product-view-winery-link:before, .catalog-product-view .product-view-winery-link:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 90%;
    height: 1px;
    display: inline-block;
    transition: all 300ms ease;
    background-color: #fff;
    transform: translateX(-50%); }
  .catalog-product-view .product-view-winery-link:after {
    top: initial;
    bottom: 0;
    height: 5px;
    transform: translateX(-50%); }
  .catalog-product-view .product-view-winery-link:hover {
    text-decoration: none; }
    .catalog-product-view .product-view-winery-link:hover:before, .catalog-product-view .product-view-winery-link:hover:after {
      width: 100%; }
  .catalog-product-view .product-view-winery-link:after, .catalog-product-view .product-view-winery-link:before {
    background-color: #171717; }

.catalog-product-view .product-view-logo {
  text-align: center; }
  .catalog-product-view .product-view-logo img {
    width: 80%;
    height: auto;
    max-width: 200px;
    max-height: 200px; }

.catalog-product-view .column.main {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .catalog-product-view .column.main .product-info-main {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.1;
    letter-spacing: 0.08rem;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    order: 2; }
    @media (min-width: 576px) {
      .catalog-product-view .column.main .product-info-main {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; } }
    .catalog-product-view .column.main .product-info-main .description-content {
      font-weight: normal;
      line-height: 1.8;
      text-transform: none;
      letter-spacing: 0; }
    .catalog-product-view .column.main .product-info-main .evp {
      text-align: right;
      font-weight: normal; }
    .catalog-product-view .column.main .product-info-main .product-icons-top {
      min-height: 200px;
      position: relative; }
      .catalog-product-view .column.main .product-info-main .product-icons-top .listout,
      .catalog-product-view .column.main .product-info-main .product-icons-top .vintageout,
      .catalog-product-view .column.main .product-info-main .product-icons-top .wineryonly,
      .catalog-product-view .column.main .product-info-main .product-icons-top .new-item,
      .catalog-product-view .column.main .product-info-main .product-icons-top .new-equipment {
        width: 150px;
        height: 150px;
        position: relative;
        display: inline-block;
        margin: 0 20px 20px 0;
        background-image: url(../images/neu-im-sortiment.svg);
        background-size: 150px 150px;
        background-repeat: no-repeat;
        background-position: left top; }
      .catalog-product-view .column.main .product-info-main .product-icons-top .new-equipment {
        background-image: url(../images/neue-ausstattung.svg); }
      .catalog-product-view .column.main .product-info-main .product-icons-top .listout {
        background-image: url(../images/ausgelistet.svg); }
      .catalog-product-view .column.main .product-info-main .product-icons-top .vintageout {
        background-image: url(../images/jahrgang_ausverkauft.svg); }
      .catalog-product-view .column.main .product-info-main .product-icons-top .wineryonly {
        background-image: url(../images/nur_ab_weingut.svg); }
    .catalog-product-view .column.main .product-info-main .page-title-wrapper.product h1 {
      font-size: 1rem;
      margin: 0; }
    .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper {
      display: none; }
      .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices {
        border-top: 1px solid #bcbcbc;
        border-bottom: 4px solid #171717; }
        .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices .price-box {
          position: relative;
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          flex: 0 0 100%;
          max-width: 100%;
          text-align: right;
          padding-right: 0; }
          .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices .price-box .label {
            display: none; }
          .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices .price-box.price-final_price:before {
            content: '';
            font-size: 0.8rem;
            font-weight: normal;
            text-transform: none; }
          .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices .price-box.price-tier-price:before {
            content: '';
            font-size: 0.8rem;
            font-weight: normal;
            text-transform: none; }
        .catalog-product-view .column.main .product-info-main .product-info-prices-wrapper .product-info-prices .price-container {
          padding: 0; }
    .catalog-product-view .column.main .product-info-main .price-details {
      color: #bcbcbc;
      font-size: 0.8rem;
      font-weight: normal;
      text-align: right; }
    .catalog-product-view .column.main .product-info-main .product-item-info {
      font-size: 1rem;
      font-weight: normal; }
      .catalog-product-view .column.main .product-info-main .product-item-info.appellation {
        font-weight: bold; }
      .catalog-product-view .column.main .product-info-main .product-item-info.details {
        border-top: 2px solid #bcbcbc; }
        .catalog-product-view .column.main .product-info-main .product-item-info.details .sku {
          font-weight: bold; }
        .catalog-product-view .column.main .product-info-main .product-item-info.details .product-info-icon {
          padding: 1rem 0 0.5rem 2.2rem;
          background-image: url(../images/Grapes.svg);
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 1.0rem; }
          .catalog-product-view .column.main .product-info-main .product-item-info.details .product-info-icon.alcohol {
            background-image: url(../images/Glass.svg);
            background-size: 0.85rem; }
          .catalog-product-view .column.main .product-info-main .product-item-info.details .product-info-icon.closure {
            background-image: url(../images/korken.svg);
            background-size: 1.15rem;
            background-position: left 1.2rem; }
          .catalog-product-view .column.main .product-info-main .product-item-info.details .product-info-icon.award {
            background-image: url(../images/auszeichnung.svg);
            background-size: 0.85rem;
            background-position: left 1.1rem; }
        .catalog-product-view .column.main .product-info-main .product-item-info.details .product-info-bio,
        .catalog-product-view .column.main .product-info-main .product-item-info.details .product-info-vegan {
          padding: 1rem 0 0.5rem 0; }
  .catalog-product-view .column.main .product.media {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1; }
    @media (min-width: 576px) {
      .catalog-product-view .column.main .product.media {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; } }
    .catalog-product-view .column.main .product.media .gallery-next-area,
    .catalog-product-view .column.main .product.media .gallery-prev-area {
      display: none; }
  .catalog-product-view .column.main h1 {
    color: #171717;
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0.075em; }
  .catalog-product-view .column.main .product-view-description {
    order: 4; }
  .catalog-product-view .column.main .product-view-downloads {
    letter-spacing: 0.08rem;
    order: 3; }
    .catalog-product-view .column.main .product-view-downloads h4 {
      font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
      text-transform: uppercase;
      color: #171717;
      font-size: 1rem; }
    .catalog-product-view .column.main .product-view-downloads .product-view-download-links a {
      color: #171717;
      display: block;
      text-transform: uppercase;
      background-image: url(../images/downloads.svg);
      background-position: right 3px;
      background-size: 1.0rem;
      background-repeat: no-repeat;
      padding-right: 20px; }
  .catalog-product-view .column.main .product-view-winery-data {
    order: 5;
    background-color: #ebe7db; }
    .catalog-product-view .column.main .product-view-winery-data .winery-logo {
      max-height: 200px;
      max-width: 200px;
      width: 80%;
      height: auto; }
      @media (min-width: 576px) {
        .catalog-product-view .column.main .product-view-winery-data .winery-logo {
          max-height: 250px;
          max-width: 250px; } }
    .catalog-product-view .column.main .product-view-winery-data .winery-links-header {
      font-size: 1.1rem;
      color: #6f152f;
      font-weight: bold;
      letter-spacing: 0.08rem; }
    .catalog-product-view .column.main .product-view-winery-data .winery-links {
      font-family: "Publico Headline", serif; }
      .catalog-product-view .column.main .product-view-winery-data .winery-links-inner {
        border-top: 4px solid #171717; }
        .catalog-product-view .column.main .product-view-winery-data .winery-links-inner a:last-child div {
          border-bottom: 4px solid #171717; }
      .catalog-product-view .column.main .product-view-winery-data .winery-links-title {
        padding: 1rem 0;
        color: #171717;
        font-size: 2.2rem;
        text-transform: uppercase;
        letter-spacing: 0.18rem;
        border-bottom: 1px solid #171717; }
      .catalog-product-view .column.main .product-view-winery-data .winery-links a:hover {
        text-decoration: none; }
        .catalog-product-view .column.main .product-view-winery-data .winery-links a:hover div {
          color: #6f152f; }

.b2b.catalog-product-view .column.main .product-info-main .product-info-prices-wrapper {
  display: block; }

.b2b.catalog-product-view .price-box {
  font-size: 1.2rem; }
  .b2b.catalog-product-view .price-box.price-offer {
    color: #6f152f; }
  .b2b.catalog-product-view .price-box.has-special {
    font-weight: 200;
    text-decoration: line-through; }
  .b2b.catalog-product-view .price-box .price-label {
    font-size: 0.8rem;
    font-weight: 200;
    text-transform: none; }

.b2b.catalog-product-view .to-cart-tool {
  line-height: 1.5; }
  .b2b.catalog-product-view .to-cart-tool > div {
    background-color: #ebe7db; }
  .b2b.catalog-product-view .to-cart-tool .field.qty {
    max-width: 60px;
    position: relative; }
    .b2b.catalog-product-view .to-cart-tool .field.qty input[type="number"] {
      background-color: #ebe7db;
      border: none;
      text-align: center;
      color: #000;
      opacity: 1;
      font-weight: 700; }

.b2b.catalog-product-view .box-tocart {
  margin-top: 25px; }
  .b2b.catalog-product-view .box-tocart .actions {
    margin-top: 15px; }
    .b2b.catalog-product-view .box-tocart .actions .action.primary.tocart {
      width: 100%; }

.filter-widget-container .filter-widget-button-col {
  margin: 10px 0; }
  .filter-widget-container .filter-widget-button-col button {
    margin: 0 10px 10px 0;
    border-radius: 0;
    background: transparent;
    border: 1px solid #bcbcbc;
    color: #bcbcbc;
    font-size: 16px;
    font-weight: regular;
    padding: 0.275rem 0.75rem; }
  .filter-widget-container .filter-widget-button-col button:focus {
    outline: none !important;
    box-shadow: none !important; }
  .filter-widget-container .filter-widget-button-col button:not(:disabled):not(.disabled):focus,
  .filter-widget-container .filter-widget-button-col button:not(:disabled):not(.disabled):active {
    border-radius: 0;
    background: transparent;
    border: 1px solid #bcbcbc;
    color: #bcbcbc; }
  .filter-widget-container .filter-widget-button-col .wine-counter {
    float: right;
    clear: both; }

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) inset, 0 0 0 rgba(126, 239, 104, 0) !important;
  outline: 0 none; }

.bootstrap-select {
  font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
  background-color: #ebe7db !important;
  margin-bottom: 10px !important;
  border-radius: 0; }
  .bootstrap-select a:focus {
    outline: none !important;
    box-shadow: none !important; }
  .bootstrap-select .btn:focus {
    outline: none !important;
    box-shadow: none !important; }
  .bootstrap-select .popover-header {
    background-color: #ebe7db;
    border-bottom: none;
    padding: 1rem 0.75rem 0.5rem;
    font-size: 16px; }
    .bootstrap-select .popover-header .close {
      float: left !important;
      font-weight: normal;
      font-size: 2rem;
      margin: -4px 10px 0 0;
      opacity: 1;
      text-shadow: none; }
      @media (min-width: 992px) {
        .bootstrap-select .popover-header .close {
          margin: -6px 10px 0 0; } }
      @media (min-width: 1200px) {
        .bootstrap-select .popover-header .close {
          margin: -9px 10px 0 0; } }
  .bootstrap-select .dropdown-toggle {
    border-radius: 0;
    background-color: #ebe7db !important;
    border: 1px solid #ebe7db !important; }
  .bootstrap-select .dropdown-menu {
    border-radius: 0;
    background-color: #ebe7db !important;
    border: 1px solid #ebe7db !important;
    padding: 0 0 5px;
    background-clip: border-box; }
    .bootstrap-select .dropdown-menu li {
      margin-bottom: 0; }
      .bootstrap-select .dropdown-menu li .dropdown-item {
        padding: 0.275rem 0.8rem;
        font-size: 16px; }
        .bootstrap-select .dropdown-menu li .dropdown-item:hover {
          font-weight: normal;
          background-color: #ebe7db; }
        .bootstrap-select .dropdown-menu li .dropdown-item:active {
          color: black;
          font-weight: 900;
          background-color: #ebe7db; }
        .bootstrap-select .dropdown-menu li .dropdown-item.selected, .bootstrap-select .dropdown-menu li .dropdown-item.active {
          color: black;
          font-weight: 900;
          background-color: #ebe7db; }
    .bootstrap-select .dropdown-menu .no-results {
      font-size: 16px;
      background-color: #ebe7db;
      margin: 0 12px; }
    .bootstrap-select .dropdown-menu.show {
      top: -3px !important; }
  .bootstrap-select .filter-option {
    border-radius: 0 !important;
    font-size: 16px;
    line-height: 20px;
    font-weight: 900; }
    .bootstrap-select .filter-option .filter-option-inner {
      border-radius: 0 !important;
      margin-top: 5px; }
      .bootstrap-select .filter-option .filter-option-inner .filter-option-inner-inner {
        text-transform: uppercase;
        margin-top: -3px; }
        @media (min-width: 992px) {
          .bootstrap-select .filter-option .filter-option-inner .filter-option-inner-inner {
            margin-top: -2px; } }
        @media (min-width: 1200px) {
          .bootstrap-select .filter-option .filter-option-inner .filter-option-inner-inner {
            margin-top: 5px; } }
  .bootstrap-select .bs-searchbox {
    padding: 0 15px 15px; }
    .bootstrap-select .bs-searchbox .form-control {
      font-size: 16px;
      border-radius: 0;
      border-top: 0 solid black;
      border-left: 0 solid black;
      border-right: 0 solid black;
      border-bottom: 1px solid black;
      background-color: #ebe7db;
      padding: 0;
      height: 30px; }

input[type=text].search-field-input {
  font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif;
  padding: 20px 12px;
  font-weight: bold;
  font-size: 16px;
  background-color: #ebe7db;
  border-color: #ebe7db; }
  input[type=text].search-field-input.is-active {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23000' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.search-widget-container .search-field {
  font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif; }
  .search-widget-container .search-field ::placeholder {
    color: #999;
    opacity: 1;
    text-transform: uppercase;
    letter-spacing: 0.0085rem; }
  .search-widget-container .search-field .search-off {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10%;
    cursor: pointer; }

.catalog-category-view .products-list {
  border-top: 1px solid #171717; }

.catalog-category-view .product-item-offer .product-image-container img {
  border: 1px solid #171717;
  padding: 4px; }

.catalog-category-view .product-item-offer .product-item-details {
  border: none; }
  .catalog-category-view .product-item-offer .product-item-details .offer-title h2 {
    text-align: left;
    margin-top: 0; }
  .catalog-category-view .product-item-offer .product-item-details .offer-text {
    font-weight: normal;
    line-height: 1.8;
    text-transform: none;
    letter-spacing: 0; }

.catalog-category-view .product-item-offer .price-box.price-offer {
  color: #6f152f;
  font-size: 1.8rem;
  font-weight: bold; }

.catalog-category-view .product-item-offer .price-box.price-normal,
.catalog-category-view .product-item-offer .price-box .price-tier-price-price {
  font-weight: normal;
  text-decoration: line-through; }

.catalog-category-view .product-item-offer .box-action a.action {
  display: block;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 0.085rem;
  color: #171717;
  background-image: url(../images/pfeil1.svg);
  background-repeat: no-repeat;
  background-position: right 34px;
  background-size: 0.85rem;
  padding: 25px 0 15px 0;
  border: none; }

.catalog-category-view .product-item-offer .product-view-text.product-view-download-links.offer-link {
  display: block;
  background-image: url(../images/downloads.svg);
  background-position: right center;
  background-size: 1.0rem;
  background-repeat: no-repeat;
  padding-right: 25px; }
  .catalog-category-view .product-item-offer .product-view-text.product-view-download-links.offer-link a {
    color: #171717;
    text-transform: uppercase;
    letter-spacing: 0.085rem; }

.all-customer-block-titles, .login-container .block .block-title, .account .column.main .block .block-title,
.customer-account-login .column.main .block .block-title,
.customer-account-create .column.main .block .block-title,
.pellegrini-customer-downloads .column.main .block .block-title, .account .column.main .form .fieldset > .legend,
.account .column.main .form-address-edit .fieldset > .legend,
.customer-account-login .column.main .form .fieldset > .legend,
.customer-account-login .column.main .form-address-edit .fieldset > .legend,
.customer-account-create .column.main .form .fieldset > .legend,
.customer-account-create .column.main .form-address-edit .fieldset > .legend,
.pellegrini-customer-downloads .column.main .form .fieldset > .legend,
.pellegrini-customer-downloads .column.main .form-address-edit .fieldset > .legend {
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.085rem;
  color: #171717;
  border-bottom: 4px solid #171717; }

.all-customer-action-links, .account .column.main a.action,
.customer-account-login .column.main a.action,
.customer-account-create .column.main a.action,
.pellegrini-customer-downloads .column.main a.action {
  font-weight: bold;
  letter-spacing: 0.085rem;
  color: #6f152f;
  background-image: url(../images/pfeil1.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 0.85rem;
  padding-right: 20px;
  margin-right: 15px;
  border: none; }

.login-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%; }
  .login-container .block {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 576px) {
      .login-container .block {
        flex: 0 0 50%;
        max-width: 50%; } }

.account .page-title,
.customer-account-login .page-title,
.customer-account-create .page-title,
.pellegrini-customer-downloads .page-title {
  font-family: "Publico Headline", serif;
  text-align: left;
  color: #171717;
  letter-spacing: 0.095rem; }

.account .column.main,
.customer-account-login .column.main,
.customer-account-create .column.main,
.pellegrini-customer-downloads .column.main {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .account .column.main .block,
  .customer-account-login .column.main .block,
  .customer-account-create .column.main .block,
  .pellegrini-customer-downloads .column.main .block {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 992px) {
      .account .column.main .block,
      .customer-account-login .column.main .block,
      .customer-account-create .column.main .block,
      .pellegrini-customer-downloads .column.main .block {
        flex: 0 0 50%;
        max-width: 50%; } }
  .account .column.main .form,
  .account .column.main .form-address-edit,
  .customer-account-login .column.main .form,
  .customer-account-login .column.main .form-address-edit,
  .customer-account-create .column.main .form,
  .customer-account-create .column.main .form-address-edit,
  .pellegrini-customer-downloads .column.main .form,
  .pellegrini-customer-downloads .column.main .form-address-edit {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    .account .column.main .form .fieldset,
    .account .column.main .form-address-edit .fieldset,
    .customer-account-login .column.main .form .fieldset,
    .customer-account-login .column.main .form-address-edit .fieldset,
    .customer-account-create .column.main .form .fieldset,
    .customer-account-create .column.main .form-address-edit .fieldset,
    .pellegrini-customer-downloads .column.main .form .fieldset,
    .pellegrini-customer-downloads .column.main .form-address-edit .fieldset {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 100%;
      max-width: 100%; }
      .account .column.main .form .fieldset.create.info, .account .column.main .form .fieldset.address,
      .account .column.main .form-address-edit .fieldset.create.info,
      .account .column.main .form-address-edit .fieldset.address,
      .customer-account-login .column.main .form .fieldset.create.info,
      .customer-account-login .column.main .form .fieldset.address,
      .customer-account-login .column.main .form-address-edit .fieldset.create.info,
      .customer-account-login .column.main .form-address-edit .fieldset.address,
      .customer-account-create .column.main .form .fieldset.create.info,
      .customer-account-create .column.main .form .fieldset.address,
      .customer-account-create .column.main .form-address-edit .fieldset.create.info,
      .customer-account-create .column.main .form-address-edit .fieldset.address,
      .pellegrini-customer-downloads .column.main .form .fieldset.create.info,
      .pellegrini-customer-downloads .column.main .form .fieldset.address,
      .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.create.info,
      .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.address {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%; }
        @media (min-width: 1600px) {
          .account .column.main .form .fieldset.create.info, .account .column.main .form .fieldset.address,
          .account .column.main .form-address-edit .fieldset.create.info,
          .account .column.main .form-address-edit .fieldset.address,
          .customer-account-login .column.main .form .fieldset.create.info,
          .customer-account-login .column.main .form .fieldset.address,
          .customer-account-login .column.main .form-address-edit .fieldset.create.info,
          .customer-account-login .column.main .form-address-edit .fieldset.address,
          .customer-account-create .column.main .form .fieldset.create.info,
          .customer-account-create .column.main .form .fieldset.address,
          .customer-account-create .column.main .form-address-edit .fieldset.create.info,
          .customer-account-create .column.main .form-address-edit .fieldset.address,
          .pellegrini-customer-downloads .column.main .form .fieldset.create.info,
          .pellegrini-customer-downloads .column.main .form .fieldset.address,
          .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.create.info,
          .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.address {
            flex: 0 0 50%;
            max-width: 50%; } }
      .account .column.main .form .fieldset.password, .account .column.main .form .fieldset.info,
      .account .column.main .form-address-edit .fieldset.password,
      .account .column.main .form-address-edit .fieldset.info,
      .customer-account-login .column.main .form .fieldset.password,
      .customer-account-login .column.main .form .fieldset.info,
      .customer-account-login .column.main .form-address-edit .fieldset.password,
      .customer-account-login .column.main .form-address-edit .fieldset.info,
      .customer-account-create .column.main .form .fieldset.password,
      .customer-account-create .column.main .form .fieldset.info,
      .customer-account-create .column.main .form-address-edit .fieldset.password,
      .customer-account-create .column.main .form-address-edit .fieldset.info,
      .pellegrini-customer-downloads .column.main .form .fieldset.password,
      .pellegrini-customer-downloads .column.main .form .fieldset.info,
      .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.password,
      .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.info {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%; }
        @media (min-width: 992px) {
          .account .column.main .form .fieldset.password, .account .column.main .form .fieldset.info,
          .account .column.main .form-address-edit .fieldset.password,
          .account .column.main .form-address-edit .fieldset.info,
          .customer-account-login .column.main .form .fieldset.password,
          .customer-account-login .column.main .form .fieldset.info,
          .customer-account-login .column.main .form-address-edit .fieldset.password,
          .customer-account-login .column.main .form-address-edit .fieldset.info,
          .customer-account-create .column.main .form .fieldset.password,
          .customer-account-create .column.main .form .fieldset.info,
          .customer-account-create .column.main .form-address-edit .fieldset.password,
          .customer-account-create .column.main .form-address-edit .fieldset.info,
          .pellegrini-customer-downloads .column.main .form .fieldset.password,
          .pellegrini-customer-downloads .column.main .form .fieldset.info,
          .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.password,
          .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.info {
            flex: 0 0 50%;
            max-width: 50%; } }
      .account .column.main .form .fieldset.create.account,
      .account .column.main .form-address-edit .fieldset.create.account,
      .customer-account-login .column.main .form .fieldset.create.account,
      .customer-account-login .column.main .form-address-edit .fieldset.create.account,
      .customer-account-create .column.main .form .fieldset.create.account,
      .customer-account-create .column.main .form-address-edit .fieldset.create.account,
      .pellegrini-customer-downloads .column.main .form .fieldset.create.account,
      .pellegrini-customer-downloads .column.main .form-address-edit .fieldset.create.account {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%; }

.checkout-cart-index .cart-backlink {
  float: left; }

.checkout-cart-index .cart-summary {
  display: none; }

.checkout-cart-index .form.form-cart {
  float: none;
  width: 100%; }

.checkout-cart-index .cart.main.actions {
  display: block;
  text-align: right; }

.checkout-cart-index .cart-totals {
  float: right;
  border: none; }

.checkout-cart-index .page-title {
  font-family: "Publico Headline", serif;
  text-align: left;
  color: #171717;
  letter-spacing: 0.095rem; }

.checkout-cart-index .product-view-winery-link {
  position: relative;
  font-size: 1.15rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 10% auto 0;
  display: inline-block;
  max-width: 250px;
  min-width: 250px;
  color: #fff;
  padding: 11px 34px;
  color: #171717; }
  .checkout-cart-index .product-view-winery-link:before, .checkout-cart-index .product-view-winery-link:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 90%;
    height: 1px;
    display: inline-block;
    transition: all 300ms ease;
    background-color: #fff;
    transform: translateX(-50%); }
  .checkout-cart-index .product-view-winery-link:after {
    top: initial;
    bottom: 0;
    height: 5px;
    transform: translateX(-50%); }
  .checkout-cart-index .product-view-winery-link:hover {
    text-decoration: none; }
    .checkout-cart-index .product-view-winery-link:hover:before, .checkout-cart-index .product-view-winery-link:hover:after {
      width: 100%; }
  .checkout-cart-index .product-view-winery-link:after, .checkout-cart-index .product-view-winery-link:before {
    background-color: #171717; }

.checkout-cart-index .cart-container .cart.table-wrapper .table th {
  border-top: none;
  font-size: 0.9rem;
  font-weight: 200;
  border-bottom: 4px solid #171717; }

.checkout-cart-index .cart-container .cart.table-wrapper .table .col {
  width: auto;
  padding-top: 10px; }
  .checkout-cart-index .cart-container .cart.table-wrapper .table .col.sku {
    line-height: 1rem; }

.checkout-cart-index .cart-container .cart.table-wrapper .table .col.item {
  flex-grow: 4;
  padding: 10px 8px 10px 8px; }

.checkout-cart-index .cart-container .cart.table-wrapper .table .col.cart-item-actions {
  flex-grow: 1;
  max-width: 20px; }

.checkout-cart-index .cart-container .cart.table-wrapper .table .to-cart-tool {
  margin: 0; }
  .checkout-cart-index .cart-container .cart.table-wrapper .table .to-cart-tool > div {
    background-color: #ebe7db; }
  .checkout-cart-index .cart-container .cart.table-wrapper .table .to-cart-tool .field.qty {
    max-width: 60px;
    position: relative; }
    .checkout-cart-index .cart-container .cart.table-wrapper .table .to-cart-tool .field.qty input[type="number"] {
      margin: 0;
      background-color: #ebe7db;
      border: none;
      text-align: center;
      color: #000;
      opacity: 1;
      font-weight: 700; }

.checkout-cart-index .cart-container .cart.table-wrapper .product-item-details {
  text-transform: uppercase;
  letter-spacing: 0.075rem; }
  .checkout-cart-index .cart-container .cart.table-wrapper .product-item-details .winery {
    line-height: 1rem;
    padding-bottom: 1rem; }
  .checkout-cart-index .cart-container .cart.table-wrapper .product-item-details .product-item-name {
    font-weight: 700;
    line-height: 1rem; }
    .checkout-cart-index .cart-container .cart.table-wrapper .product-item-details .product-item-name a {
      color: #171717; }

.checkout-cart-index .cart-container .cart.table-wrapper .price-including-tax,
.checkout-cart-index .cart-container .cart.table-wrapper .price-excluding-tax {
  font-size: inherit; }
  .checkout-cart-index .cart-container .cart.table-wrapper .price-including-tax .price,
  .checkout-cart-index .cart-container .cart.table-wrapper .price-excluding-tax .price {
    font-weight: 200; }

.checkout-cart-index .cart-container .data.table.totals tr.totals.sub th,
.checkout-cart-index .cart-container .data.table.totals tr.totals.sub td {
  border: none; }

.checkout-cart-index .cart-container .qty-steps > div {
  position: relative; }

.checkout-cart-index .cart-container .qty-steps p {
  margin: 0;
  color: #bcbcbc;
  padding-left: 40px; }
  .checkout-cart-index .cart-container .qty-steps p.step-reached {
    color: #171717; }
    .checkout-cart-index .cart-container .qty-steps p.step-reached:before {
      font-size: 28px;
      line-height: 28px;
      color: #6f152f;
      content: "";
      font-family: "icons-blank-theme";
      margin: -14px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      left: 0;
      top: 18px;
      width: 40px;
      position: absolute;
      text-align: center; }

.g-recaptcha {
  margin-bottom: 25px; }
