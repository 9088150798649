.d-none {
    display: none;
    width: 0;
    height: 0;
}

.dummy-country-start {
    background-color: $color-beige1;
    height: 300px;
}

.thin-line {
    border-top: 1px solid $color-black;
}
