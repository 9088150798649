.messages {
    .success.message {
        background-color: $color-beige1;
        color: $color-black;

        a {
            color: $color-red;
        }
    }

    .success.message > :first-child::before {
        color: $color-red;
        line-height: 2.1rem;
    }
}
