.we-region-data {
    border-bottom: 1px solid $color-black;

    .we-region-content-row {
        .we-icon {
            height: 200px;
            position: relative;

            svg {
                position: absolute;
                bottom: 10px;
                left: 10px;
                z-index: 10;
                vertical-align: bottom;
                max-width: 80px;
            }
        }

        .we-region-content {
            border-top: 1px solid $color-black;
            padding-top: 10px;
        }
    }
}
