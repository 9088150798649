//body
html {
    position: relative;
    min-height: 100%;
}

html {
    font-size: 1rem;
}

@include media-breakpoint-up(sm) {
    html {
        font-size: 1.05rem;
    }
}


@include media-breakpoint-up(lg) {
    html {
        font-size: 1.1rem;
    }
}


@include media-breakpoint-up(xl) {
    html {
        font-size: 1.3rem;
    }
}


.l-body, .page-wrapper {
    background-color: $color-white;
    color: $color-black;

    // 88px (header height) + 55px (submenu height) on typo3
    padding-top: 143px;
}
body.b2b .page-wrapper {
    // header height and submenu height plus 58px for business customers
    padding-top: 200px;
}

.nav-sections, .breadcrumbs {
    // for now
    display: none;
}

main.page-main {
    @include make-container;

    max-width: #{map-get($container-max-widths, sm)};

    @include media-breakpoint-up(xs) {
        max-width: #{map-get($container-max-widths, xs)};
    }


    @include media-breakpoint-up(md) {
        max-width: #{map-get($container-max-widths, md)};
    }


    @include media-breakpoint-up(lg) {
        max-width: #{map-get($container-max-widths, lg)};
    }


    @include media-breakpoint-up(xl) {
        max-width: #{map-get($container-max-widths, xl)};
    }


    @include media-breakpoint-up(xxl) {
        max-width: #{map-get($container-max-widths, xxl)};
    }
}

