.checkout-cart-index {

    .cart-backlink {
        float: left;
    }

    .cart-summary {
        display: none;
    }

    .form.form-cart {
        float: none;
        width: 100%;
    }

    .cart.main.actions {
        display: block;
        text-align: right;
    }

    .cart-totals {
        float: right;
        border: none;
    }

    .page-title {
        @extend .mt-4;
        @extend .mb-4;
        font-family: $secondary-font;
        text-align: left;
        color: $color-black;
        letter-spacing: 0.095rem;
    }

    .product-view-winery-link {
        @include more-link-with-border;
        color: $color-black;

        &:after,
        &:before {
            background-color: $color-black;
        }
    }

    .cart-container {
        .cart.table-wrapper {
            .table {
                th {
                    border-top: none;
                    font-size: 0.9rem;
                    font-weight: 200;
                    border-bottom: 4px solid $color-black;
                }

                .col {
                    width: auto;
                    padding-top: 10px;

                    &.sku {
                        line-height: 1rem;
                    }
                }

                .col.item {
                    flex-grow: 4;
                    padding: 10px 8px 10px 8px;
                }

                .col.cart-item-actions {
                    flex-grow: 1;
                    max-width: 20px;
                }
                .to-cart-tool {
                    margin: 0;

                    > div {
                        background-color: $color-beige1;
                    }

                    .field.qty {
                        max-width: 60px;
                        position: relative;

                        input[type="number"] {
                            margin: 0;
                            background-color: $color-beige1;
                            border: none;
                            text-align: center;
                            color: #000;
                            opacity: 1;
                            font-weight: 700;
                        }

                        // &::after {
                        //     position: absolute;
                        //     left: 0;
                        //     right: 0;
                        //     top: 0;
                        //     bottom: 0;
                        //     content: '';
                        // }
                    }
                }
            }

            .product-item-details {
                text-transform: uppercase;
                letter-spacing: 0.075rem;

                .winery {
                    line-height: 1rem;
                    padding-bottom: 1rem;
                }
                .product-item-name {
                    font-weight: 700;
                    line-height: 1rem;

                    a {
                        color: $color-black;
                    }
                }
            }

            .price-including-tax,
            .price-excluding-tax {
                font-size: inherit;

                & .price {
                    font-weight: 200;
                }
            }
        }

        .data.table.totals {
            tr.totals.sub {
                th,
                td {
                    border: none;
                }
            }
        }

        .qty-steps {
            & > div {
                position: relative;
            }

            p {
                margin: 0;
                color: $color-grey;
                padding-left: 40px;

                &.step-reached {
                    color: $color-black;

                    &:before {
                        font-size: 28px;
                        line-height: 28px;
                        color: $color-red;
                        content: "";
                        font-family: "icons-blank-theme";
                        margin: -14px 0 0;
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                        left: 0;
                        top: 18px;
                        width: 40px;
                        position: absolute;
                        text-align: center;
                    }
                }
            }
        }
    }
}
