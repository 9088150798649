.we-structure {
    margin: 120px 0;

    &-country {
        &-title {
            padding: 1rem 0;
            border-top: 4px solid $color-black;
            color: $color-black;
            font-size: 2.2rem;
        }

        a:hover {
            text-decoration: none;

            div {
                color: $color-red;
            }
        }

        .we-country-last {
            border-bottom: 4px solid $color-black;
        }
    }

    &-region {
        padding: 15px 0;

        &-title {
            border-top: 1px solid $color-black;
            font-family: $secondary-font;
            font-weight: 700;
            font-size: 1.2rem;
            color: $color-red;
        }
    }

    &-winery-title {
        text-align: center;

        img {
            height: auto;
            min-width: 60%;
            max-width: 100%;
            padding: 15px 0;
        }
    }
}
