#close-menu-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: 24px 0;
}

.c-menu-overlay {
    z-index: $zindex-popover;
    width: 100%;
    top: 0;
    bottom: 0;
    position: fixed;

	&--close {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .c-menu-overlay-bg {
        background-color: #fff;
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 50vw;
    }

    .c-menu-container {
        height: 100vh;
		pointer-events: none;
    }

    &.inactive {
        display: none;
    }

    .c-mainmenu {
        text-align: center;
        font-family: $secondary-font;
        text-transform: uppercase;
        pointer-events: initial;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        .navbar {
            padding-left: 0;
        }

        ul.navbar-nav {
            width: 100%;

            li {
                margin-bottom: 0;
            }
        }

        a.nav-link {
            font-size: 1.6rem;
            letter-spacing: 0.15rem;
            color: $color-black;

            &:hover,
            &.active {
                color: $color-red;
            }
        }
    }

    /* Footermenu Inhalte im Hauptmenu */
    .footermenu {
        color: $color-black;
        font-family: $primary-font;
        text-align: left;
        pointer-events: initial;

        a {
            color: $color-black;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }

        ul > li {
            margin-bottom: 0;
        }
    }

    .c-menu-container-inner {
        height: 100vh;
        background-color: $color-white;
        box-shadow: 2px 0 3px rgba(0, 0, 0, 0.16) !important;

        @include media-breakpoint-up(md) {
            width: 60%;
        }
    }
}
