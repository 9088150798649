.we-winery-data {
    background-color: $color-beige1;
    margin: 60px 0;
    padding: 50px 15px;

    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
    }

    h4 {
        color: $color-black;
        text-transform: uppercase;
    }

    .we-winery-thinline {
        border-bottom: 1px solid $color-black;
    }

    .we-winery-fatline {
        border-bottom: 4px solid $color-black;
        margin-bottom: 0.8rem;
    }

    .we-winery-location {
        @include secondary-font-red-bold;
    }
}
